import { ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TableColumn, SelectionType, DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppConfigs } from 'src/app/common/app-configs';
import { CommonJs } from 'src/app/common/common-js';
import { DataService } from 'src/app/services/data.service';
import { DatatableService } from 'src/app/services/datatable/datatable.service';
import { GridService } from 'src/app/services/grid.service';
import { ToastrService } from 'src/app/services/toastr.service';
import { columns } from '../utility/column-generator';
import swal from 'sweetalert2';
import * as _ from "lodash";
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import Pusher from 'pusher-js/worker';
import { ThumbnailModalServiceService } from 'src/app/services/thumbnail-modal-service.service';
import { ConfirmationPopupComponent } from 'src/app/common/confirmation-popup/confirmation-popup.component';
import Echo from 'laravel-echo'
import { environment } from 'src/environments/environment';
import { PusherService } from 'src/app/services/pusher.service';
declare var $: any;

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.css']
})
export class TaskListComponent implements OnInit {
  bsModalRef: BsModalRef;
  //Assets var
  tasksObj: any = {};
  isFilterApplied: boolean = false;

  // Datatable var

  rows: any = [];
  temp = [];
  currentPage: number = 0;
  openedPage: number = 0;
  pageSize: number = 100;
  totalItems: number = 0;
  isLoading: boolean = true;
  pagePayload: any = {
    page: 0,
    itemsPerPage: 100,
  }
  public readonly pageLimitOptions = [
    { value: 50 },
    { value: 100 },
    { value: 200 },
    { value: 300 },
  ];
  public dataTableColumns: TableColumn[] = [];
  selected = [];
  SelectionType: SelectionType[] = [];
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('image', { static: false }) public image: TemplateRef<any>;
  @ViewChild('icon', { static: false }) public icon: TemplateRef<any>;
  @ViewChild('idsT', { static: false }) public idsT: TemplateRef<any>;
  @ViewChild('complexity', { static: false }) public complexity: TemplateRef<any>;
  @ViewChild('bid_days', { static: false }) public bid_days: TemplateRef<any>;
  @ViewChild('status', { static: false }) public status: TemplateRef<any>;
  @ViewChild('complition', { static: false }) public complition: TemplateRef<any>;
  @ViewChild('info', { static: false }) public info: TemplateRef<any>;
  @ViewChild('shotDetail', { static: false }) public shotDetail: TemplateRef<any>;
  @ViewChild('departmentStatus', { static: false }) public departmentStatus: TemplateRef<any>;
  @ViewChild('userType', { static: false }) public userType: TemplateRef<any>;
  @ViewChild('startDate', { static: false }) public startDate: TemplateRef<any>;
  @ViewChild('endDate', { static: false }) public endDate: TemplateRef<any>;
  @ViewChild('actualStartDate', { static: false }) public actualStartDate: TemplateRef<any>;
  @ViewChild('actualEndDate', { static: false }) public actualEndDate: TemplateRef<any>;
  @ViewChild("hdrTpl", { static: true }) hdrTpl: TemplateRef<any>;
  @ViewChild('container', { static: true }) containerRef: ElementRef;
  deleteAllId = [];
  checboxColum = {
    prop: 'selected',
    name: '',
    sortable: false,
    frozenLeft: true,
    draggable: true,
    resizable: false,
    headerCheckboxable: true,
    checkboxable: true,
    maxWidth: 45,
    width: 45,
    minWidth: 45,
  }

  //Config var
  public FilterColumns: Array<any> = ['name'];
  public config: any = {
    paging: true,
    columnSort: { sortingType: '', columnName: '' },
    FilterByColumns: {
      "department": null,
      "version": null,
      "complexity": null,
      "assigned_to": null,
      "status": null,
      "type": null,
      "undertaking": null
    },
    sortNew: {},
    itemsPerPage: this.pagePayload.itemsPerPage,
    start: this.pagePayload.page,
    sorting: { columns: this.dataTableColumns },
    filtering: { filterString: '' },
    where: { 'project_id': this.tasksObj.projectId, show_all_tasks: false },
    export: false,
    isLoadingFirstTime: true,
    isClearFilter: false,
    isAdvanceSearchApplied: false
  };

  isEpisodicSeries: boolean = false;
  _opened: boolean = false;
  _loadSideWindow: boolean = false;
  _ng_sidebar_data = {};
  _loadImportSideWindow: boolean = false;
  assetType: Array<any> = [];
  assetSubType: string = 'All';
  settings: any = JSON.parse(localStorage.getItem('settings'));

  //Import
  _ng_import_type: string;
  _import_opened: boolean = false;
  _manual_task_import_opened: boolean = false;

  _ng_sidebar_manage_col_data = {};
  _manage_col_opened: boolean = false;
  _loadManageColSideWindow: boolean = false;
  clientCode = localStorage.getItem('access_code');
  statusOptions = [];
  priorityOptions = [{ id: 'All', name: 'All' }, { id: 'High', name: 'High' }, { id: 'Normal', name: 'Normal' }, { id: 'Low', name: 'Low' }];
  RevertStatusOptions = [];
  statusTag = {
    1: { className: 'label label-success', display: 'Active' },
    0: { className: 'label label-default label-light-grey', display: 'Inactive' }
  };
  is_external_review: any;
  currentRole: string;
  projectRoles = JSON.parse(localStorage.getItem('projectRoles'));
  _ng_sidebar_task_details_data = {};
  _task_details_opened: boolean = false;
  _loadTaskDetailsSideWindow: boolean = false;
  isAdvanceSearchEnabled: boolean = false;
  isAdvanceSearchSubmitted: boolean = false;
  isAdvanceSearchApplied: boolean = false;
  tasksSearch: { [index: string]: any } = {};
  tasksData: { [index: string]: any } = {};
  episodesList: { [index: string]: any } = {};
  sequenceList: { [index: string]: any } = {};
  shotList: { [index: string]: any } = {};
  assetList: { [index: string]: any } = {};
  phaseList: { [index: string]: any } = {};
  assignedUnassignedList = [{ "id": 0, "name": "Unassigned" }, { "id": 1, "name": "Assigned" }];
  typeOptions = [{ "id": 0, "name": "R&D" }, { "id": 1, "name": "Production" }];
  undertakingOptions = [{ "id": 'In House', "name": "In House" }, { "id": 'Outsourced', "name": "Outsourced" }];
  isCompletionOpen = [];
  artistsCompletionArr = [];
  errors: { [index: string]: any } = {};
  userId = localStorage.getItem('userId');
  user = JSON.parse(localStorage.getItem('user'));
  isChecked: any;
  isTodayChecked: any;
  _task_reschedule_opened: boolean = false;
  _loadRescheduleSideWindow: boolean = false;
  _ng_sidebar_reschedule_data = {};
  globalSelect: Array<any> = [];
  fromNotify: string = '';

  selectedTab: string = 'All';

  visiblePhases: any[] = [];
  phasesExist = {};

  isFullScreen:boolean = false;
  previousOffsetY:number = 0;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private gridService: GridService,
    private datatableService: DatatableService,
    private router: Router,
    public commonJs: CommonJs,
    public appConfigs: AppConfigs,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private titleService: Title,
    private modalService: BsModalService,
    private cd: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private thumbnailModalService: ThumbnailModalServiceService,
    private elementRef: ElementRef,
    private pusherService: PusherService
  ) {
    this.tasksObj.isAssetPermissions = {};
    this.tasksObj.subType = [];
    this.tasksObj.episodeSequence = [];
    this.tasksObj.departmentSelected = [];
    this.tasksObj.complexity = this.appConfigs.sht_ast_complexity;
    this.datatableService.setParam(this.dataTableColumns, 'columns');
    this.datatableService.setParam(this.config, 'config');
    this.datatableService.setParam(this.FilterColumns, 'FilterColumns');
    this.datatableService.setParam(this.tasksObj.apiRoutePrefix + 'List', 'serverUrl');
    this.tasksObj.unAssignedCount = 0;
    this.tasksObj.selectedPriority = { id: 'All', name: 'All' };
  }

  ngOnInit() {
    this.containerRef.nativeElement.scroll({ top: 0, behavior: 'smooth' });
    console.log(localStorage.getItem('phasesExist'));
    this.tasksObj.activeType = 0;
    this.titleService.setTitle(this.settings.html_prefix + ' Tasks');

    if (localStorage.getItem('phasesExist')) {
      this.phasesExist = JSON.parse(localStorage.getItem('phasesExist'));
      // console.log(this.phasesExist);
    } else if (this.commonJs.phasesExist) {
      this.phasesExist = this.commonJs.phasesExist;
    } else {
      this.phasesExist = { pre: 1, character: 1, set: 1, prop: 1, shot: 1, post: 1 };
    }

    this.tasksObj.isTaskViewPermission = this.commonJs.checkAccess('tasks', 'view', { 'baiPass': ['admin'] }, this.tasksObj.projectId);
    this.tasksObj.isTaskAddPermission = this.commonJs.checkAccess('tasks', 'add', { 'baiPass': ['admin'] }, this.tasksObj.projectId);
    this.tasksObj.isTaskEditPermission = this.commonJs.checkAccess('tasks', 'edit', { 'baiPass': ['admin'] }, this.tasksObj.projectId);
    this.tasksObj.isTaskDeletePermission = this.commonJs.checkAccess('tasks', 'delete', { 'baiPass': ['admin'] }, this.tasksObj.projectId);
    this.tasksObj.isTaskAssignAddPermission = this.commonJs.checkAccess('task_assignment', 'add', { 'baiPass': ['admin'] }, this.tasksObj.projectId);
    this.tasksObj.isTaskAssignViewPermission = this.commonJs.checkAccess('task_assignment', 'view', { 'baiPass': ['admin'] }, this.tasksObj.projectId);
    this.tasksObj.isTaskAssignEditPermission = this.commonJs.checkAccess('task_assignment', 'edit', { 'baiPass': ['admin'] }, this.tasksObj.projectId);
    this.tasksObj.isTaskAssignDeletePermission = this.commonJs.checkAccess('task_assignment', 'delete', { 'baiPass': ['admin'] }, this.tasksObj.projectId);
    this.activatedRoute.params.subscribe((params: Params) => {
      this.tasksObj.projectId = params['id'];
      this.config.where.project_id = this.tasksObj.projectId;
      this.is_external_review = localStorage.getItem('isExternalReview');
      if (params['type'] != undefined) {
        this.assetSubType = params['type'];
        this.assetType = this.config.where['type'] = [params['type']];
      }
      // Get the start and end parameters from the URL
      if (this.route.snapshot.queryParamMap.get('active')) {
        const active: any = this.route.snapshot.queryParamMap.get('active');
        const activeNumber: number = Number(active);

        this.changeActiveType(activeNumber);
      }
    });
    this.currentRole = this.projectRoles['project_' + this.tasksObj.projectId];
    this.spinner.hide();
    this.getProjectsById(this.tasksObj.projectId).then((res) => {
      this.createColumns();
      this.getStatusList();
    });


    //  Pusher js live update data without reload page /
    if (environment.production) {
      const channel = this.pusherService.subscribeToChannel(`tasks`);
      // const channel = pusher.subscribe(`${'tasks.'}${this.tasksObj.projectId}`);
      channel.bind(`tasks`, (data) => {
        if(data.projectId == this.tasksObj.projectId){
          this.isLoading = true;
          this.getTasksList();
        }
      });
    }

    // this.dataService.notifyObservable$.subscribe(res => {
    //   if (res.refresh) {
    //     this._import_opened = true;
    //     this._ng_import_type = 'task';
    //     this.fromNotify = 'modalImport';
    //   }
    // });
    // this.websocket();
    /** *************************************************** END ***************************************************/
    this.dataService.pagePreferanceGet('task_list_page').then((res: any) => {
      if (res) {

        let response = res.data;
        // console.log(res);

        this.tasksObj.activeType = response.activeTab;
        switch (response.activeTab) {
          case 0:
            this.selectedTab = 'All';
            break;
          case 1:
            this.selectedTab = 'Pre/Post';
            break;
          case 2:
            this.selectedTab = 'Asset';
            break;
          case 3:
            this.selectedTab = 'Shot';
            break;
        }

        this.getAdvancedFilterPreferance();

      }
    }, (err: any) => {
      console.warn("Error while fetching page set")
    });
  }

  // websocket(){
  //   const echo = new Echo({
  //     broadcaster: 'pusher',
  //     key: 'websocket',
  //     cluster: 'mt1',
  //     wsHost: '172.30.0.4',
  //     encrypted: false,
  //     wsPort: 6001,
  //     enabledTransports: ['ws'],
  //     forceTLS: false,
  //     debug: true,
  //     disableStats: true
  //   })
  //   echo.channel('refetch-data')
  //   .listen('DataRefetchEvent', (resp:any) => {
  //     if(resp.is_refetch_data){
  //       this.isLoading = true;
  //       this.getTasksList();
  //     }
  //   })
  // }

  // open glbal image thumbnail viewer
  openImageModal(imageUrl: string): void {
    this.thumbnailModalService.openThumbnailModal(imageUrl);
  }

  // Get status list
  getStatusList() {
    return new Promise((resolve, rejected) => {
      this.dataService.getOtherData('getStatusList', {})
        .subscribe((success) => {
          this.statusOptions = success.data;
          resolve(200);
        }, (rejected) => {

        });
    });
  }

  /** On select the row */
  // onSelect(row) {
  //   console.log(row)
  //   this.selected = row.selected;
  //   this.deleteAllId = row.selected.map(obj => obj.id);
  // }

  // For row identity by Id
  getId(row) {
    return row.id;
  }

  onSelect({ selected }) {

    this.globalSelect[this.openedPage] = selected;
    if (selected.length > 0) {
      this.selected = selected;
    }

    // this.deleteAllId = this.selected.map(obj => obj.id);
    this.deleteAllId = [];
    this.globalSelect.forEach((element) => {
      element.map((obj) => {
        // if(!this.deleteAllId.includes(obj.id)){
        this.deleteAllId.push(obj.id);
        // }
      });
    });
    // console.log(this.globalSelect);
    let totalSelected = 0;
    this.globalSelect.forEach((ele) => {
      totalSelected += ele.length;
    });

    // To show changed number of selected items
    const element = this.elementRef.nativeElement.querySelector('.page-count span');
    if (element) {
      element.innerHTML = totalSelected + ' selected / ';
      // element.innerHTML = selectedRows.length + ' selected / ' + this.totalCount + ' total';
    }

  }


  /** Create Column */
  private createColumns(): void {
    let cellTemplate: any = {};
    let tempColumn: any;
    setTimeout(() => {
      cellTemplate = {
        ids: this.idsT,
        image: this.image,
        icon: this.icon,
        complexity: this.complexity,
        status: this.status,
        bid_days: this.bid_days,
        hdrTpl: this.hdrTpl,
        userType: this.userType,
        info: this.info,
        complition: this.complition,
        startDate: this.startDate,
        endDate: this.endDate,
        actualStartDate: this.actualStartDate,
        actualEndDate: this.actualEndDate
      };

      this.dataTableColumns = [];
      tempColumn = columns('tasks', cellTemplate);
      // this.dataTableColumns = columns('tasks', cellTemplate);
      // console.log(this.selectedTab, this.tasksObj.activeType);
      if (this.selectedTab != 'All') {
        tempColumn = tempColumn.filter((ele) => (ele.name != 'Icon'));
      }
      tempColumn.unshift(this.checboxColum);
      // this.getTasksList();
      this.columnSet().then((response: any) => {
        if (response.status == 'success' && response.data.length != 0) {

          console.log(response.data.map((ele)=>{if(ele.prop=='id' && ele.name=='Actions'){ele.show=true;} return ele;}));

          const rearrangedColumns = [];
          for (const item of response.data) {
            const matchingColumn = tempColumn.find(obj => obj.prop === item.prop && item.show);
            if (matchingColumn) {
              rearrangedColumns.push(matchingColumn);
            }
          }
          for (const obj of tempColumn) {
            if (!response.data.some(item => item.prop === obj.prop && item.show)) {
              rearrangedColumns.push(obj);
            }
          }
          tempColumn = rearrangedColumns;
          this.dataTableColumns = tempColumn.filter((obj: any) => {
            if (!obj.dynamicColumn) {
              let temp_column_preferences = response.data.filter((obj) => (this.isEpisodicSeries ? obj : obj.prop != "season_and_episode"));

              return temp_column_preferences.some(item => item.prop === obj.prop && item.show)
            } else {
              return obj
            }
          });
        } else {
          const columnsToShow = ['thumbnail', 'info', 'shot_number', 'actual_start_date', 'actual_end_date', 'department', 'bid_days', 'assigned_to', 'actualStatus', 'id'];
          this.dataTableColumns = tempColumn.map((column: any) => {
            if (columnsToShow.includes(column.prop)) {
              return column;
            } else {
              return null;
            }
          }).filter(column => column !== null);
        }
        this.dataTableColumns.unshift(this.checboxColum);
      }, (err: any) => {
        console.warn("Error while fetching column set")
      });
    });
  }


  onUserEvent(event) {
    if (event.type == "click") {
      const row = event.row;
      const column = event.column;
      let shotData = {
        'type': 'shot',
        'task_dept_column': 'department_id',
        'task_dept_id': column.task_dept_id,
        'department': column.name,
        'data': row,
        'status': row.shotStatus,
        'actualStatus': row.shotStatus,
      }
      if (column.dynamicColumn && row[column.prop] != "") {
        // if (this.currentRole != 'client') {
        // this.openTaskAssignAndCommentsSideWindow(shotData);
      }
    }
  }

  // TODO[Dmitry Teplov] wrap dynamic limit in a separate component.
  public onLimitChange(limit: any): void {
    this.selected = [];
      // To show changed number of selected items
      const element = this.elementRef.nativeElement.querySelector('.page-count span');
      if (element) {
        element.innerHTML = 0 + ' selected / ';
      }
    this.globalSelect = [];

    this.changePageLimit(limit);
    this.table.limit = this.pageSize;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor((this.table.rowCount - 1) / this.table.limit);
      }
    });
  }

  private changePageLimit(limit: any): void {
    this.isLoading = true;
    this.pageSize = parseInt(limit, 10);
    this.dataService.pagePreferancePost({
      pageSize: this.pageSize,
      currentPage: this.config.start,
      pageIndex: this.table.offset,
      user_id: JSON.parse(localStorage.getItem('user')).id,
      project_id: this.tasksObj.projectId
    }, 'task_list_page');
    this.config.itemsPerPage = this.pageSize;
    this.getTasksList();
  }

  // on sorting a column
  onSort(event) {
    let sortedData = {
      sortingType: event.newValue,
      columnName: event.column.prop
    }

    this.config.columnSort = sortedData;

    this.dataService.pagePreferancePost({
      pageSize: this.pageSize,
      currentPage: this.config.start,
      pageIndex: this.table.offset,
      sortData: sortedData,
      user_id: JSON.parse(localStorage.getItem('user')).id,
      project_id: this.tasksObj.projectId
    }, 'task_list_page');

  }

  /**
  * Description: Page change event
  * @param e pager object
  */
  firstTimeLoad: boolean = false;
  onPageChange(e): void {
    // this.currentPage = e.offset;
    this.openedPage = e.offset;
    this.selected = this.globalSelect[this.openedPage] != undefined ? this.globalSelect[this.openedPage] : [];

    // this.selected = []// Reset the selected item to null on pagination change
    this.isLoading = true;
    this.pagePayload.page = e.offset * this.pageSize;
    this.config.start = this.pagePayload.page;
    if (this.firstTimeLoad) {
      this.table.offset = this.openedPage;
      this.dataService.pagePreferancePost({
        pageSize: this.pageSize,
        currentPage: this.config.start,
        pageIndex: this.table.offset,
        user_id: JSON.parse(localStorage.getItem('user')).id,
        project_id: this.tasksObj.projectId
      }, 'task_list_page');
      this.getTasksList();
    } else {
      this.dataService.pagePreferanceGet('task_list_page').then((res: any) => {
        if (res.data.length == undefined) {
          this.pageSize = res.data.pageSize;
          this.config.itemsPerPage = res.data.pageSize;
          this.config.start = res.data.currentPage;
          this.config.columnSort = res.data.sortData;
          this.table.offset = res.data.pageIndex;
          this.getTasksList();
        } else {
          this.getTasksList();
        }
      }, (err: any) => {
        console.warn("Error while fetching page set")
      });
    }
    this.firstTimeLoad = true;
  }


  /** Get Asset list */

  getTasksList() {
    this.config.selectedTab = this.selectedTab;
    this.spinner.hide();
    this.gridService.postData('tasksList', this.config).subscribe((res: any) => {
      if (!this.isLoading) {
        this.isLoading = true;
      }
      if (this.config.export || this.config.where.exportForBulkTransfer || this.config.where.exportUnassigned) {
        // if (this.config.export || this.config.where.exportForBulkTransfer || this.config.where.exportUnassigned || this.config.where.assignable) {

        if (res.data) {
          window.location.href = res.data;
          this.isLoading = false;
          setTimeout(() => {
            this.gridService.myEventEmitterForProgressModal.emit(true);
          }, 200);
        }
        this.config.export = false;
      } else {
        this.tasksObj.unAssignedCount = res.unAssignedCount;
        this.totalItems = res.totalElements;
        this.isFilterApplied = res.isFilterApplied;
        if (res.filterArr) {
          if (res.filterArr.where) {
            this.config.FilterByColumns.department = res.filterArr.FilterByColumns.department;
            this.tasksObj.departmentSelected = res.filterArr.where.department;
            this.config.FilterByColumns.version = res.filterArr.FilterByColumns.version;
            this.config.FilterByColumns.complexity = res.filterArr.FilterByColumns.complexity;
            this.config.FilterByColumns.assigned_to = res.filterArr.FilterByColumns.assigned_to;
            this.config.FilterByColumns.status = res.filterArr.FilterByColumns.status;
            this.config.FilterByColumns.type = res.filterArr.FilterByColumns.type;
            this.config.FilterByColumns.undertaking = res.filterArr.FilterByColumns.undertaking;
          }
        }

        // res.data.forEach(row => {
        //   row.isSelected = res.data.some(selectedItem => selectedItem.id === row.id);
        // });
        // this.selected = [];
        this.temp = res.data;
        this.rows = [...this.temp];
        // console.log(this.rows);
        this.config.export = false;
        this.isLoading = false;
        this.cd.markForCheck();
      }
    }, (error) => {
      // console.log('error spotted');
      this.config.export = false;
      this.isLoading = false;
    });
    this.gridService.progressFunction();

  }

  /** When Filter is there for department call dpeartment list api */
  filterDepartmentList() {
    this.dataService.getOtherData('getTasksDepartments', { 'projectId': this.tasksObj.projectId, 'query': event['query'], 'where': this.config.where })
      .subscribe((success) => {
        this.tasksObj.department = success.data;
      }, (rejected) => {

      });
  }

  /** Action Button */
  actionClick(type: string, row: any) {
    let id: any = row.id
    if (type == 'delete') {
      const config = {
        initialState: {
          title: 'Delete task',
          message: "Are you sure you want to delete this task(s)?",
        },
        class: 'modal-md custom-modal-css',
        centered: true,
        animated: false
      };
      this.bsModalRef = this.modalService.show(ConfirmationPopupComponent, config);
      // Subscribe to the onConfirmAction event
      this.bsModalRef.content.onConfirmAction.subscribe(() => {
        // Handle the confirmation logic here
        this.dataService.deleteData('tasks' + 'Destroy', { id })
          .subscribe((success) => {
            if (success.status == 'error') {
              this.toastrService.notify('error', '', success.message);
            } else {
              this.toastrService.notify('success', '', success.message);
              this.isLoading = true;
              this.getTasksList();
            }
          }, (rejected) => {

          });
      });


      // let that = this;
      // swal.fire({
      //   title: 'Delete  task(s)',
      //   text: "Are you sure you want to delete this task(s)?",
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonColor: "#DD6B55",
      //   confirmButtonText: "Delete",
      //   cancelButtonText: "Cancel",
      // }).then((result) => {
      //   if (result.value) {
      //     that.dataService.deleteData('tasks' + 'Destroy', { id })
      //       .subscribe((success) => {
      //         this.selected = [];
      //         if (success.status == 'error') {
      //           //that.toastrService.notify('error','', success.message);
      //         } else {
      //           this.isLoading = true;
      //           this.getTasksList();
      //         }
      //         that.toastrService.notify(success.status, '', success.message);

      //       }, (rejected) => {
      //         this.selected = [];
      //       });
      //   } else {
      //     //console.log('cancelled');
      //   }
      // });
    } else {
      this.selected = [];
        // To show changed number of selected items
        const element = this.elementRef.nativeElement.querySelector('.page-count span');
        if (element) {
          element.innerHTML = 0 + ' selected / ';
        }
      this.spinner.hide();
      if ((row.id != undefined && row.id != '')) {
        this._ng_sidebar_data['taskid'] = row.id;
      } else if (row.length > 0) {
        this._ng_sidebar_data['taskid'] = row;
      }
      this._ng_sidebar_data['task'] = true;
      this._ng_sidebar_data['projectId'] = this.tasksObj.projectId;
      this._ng_sidebar_data['department_id'] = row.department_id;
      this._ng_sidebar_data['mode'] = 'edit';
      this._ng_sidebar_data['info'] = row.info;
      this._ng_sidebar_data['auto_created'] = row.auto_created;
      this._ng_sidebar_data['undertaking'] = row.undertaking;
      this._ng_sidebar_data['number'] = row.number;
      this._ng_sidebar_data['name'] = row.name;
      this._ng_sidebar_data['department'] = row.department;
      this._ng_sidebar_data['thumbnail'] = row.thumbnail;
      this._ng_sidebar_data['is_assign'] = false;
      this._ng_sidebar_data['assigned_val'] = row.assigned_to;
      this._ng_sidebar_data['is_multiple_assign'] = row.is_multiple_assign;
      this._opened = true;
    }

  }

  /** Search Auto complete */
  search(event: any, type: string) {
    this.spinner.hide();
    if (type == 'complexity') {
      this.tasksObj.complexity = [...this.appConfigs.sht_ast_complexity]
    }
    else if (type == 'episodeSequence') {
      if (this.isEpisodicSeries) {
        this.dataService.getOtherData('episodesItems', { 'project_id': this.tasksObj.projectId, 'fromCreateTask': true, 'query': event['query'] })
          .subscribe((success) => {
            this.tasksObj.episodeSequence = success.data;
          }, (rejected) => {
          });
      } else {
        this.dataService.getOtherData('sequencesItems', { 'project_id': this.tasksObj.projectId, 'fromCreateTask': true, 'query': event['query'] })
          .subscribe((success) => {
            let depSplit: any;
            let phaseName: any;
            this.tasksObj.episodeSequence = success.data;
            this.tasksObj.episodeSequence = success.data.filter((data) => {
              depSplit = data.department.split('- ').pop();
              if (depSplit == phaseName) {
                return data;
              }
            });
          }, (rejected) => {
          });
      }
    }
    else if (type == 'sub_type') {
      this.dataService.getOtherData('searchItemList', { list_name: 'asset_subtypes', 'query': event['query'] })
        .subscribe((success) => {
          this.tasksObj.subType = success.data;
        }, (rejected) => {
        });
    } else if (type == 'department') {
      this.dataService.getOtherData('getTasksDepartments', { 'projectId': this.tasksObj.projectId, 'query': event['query'], 'where': this.config.where })
        .subscribe((success) => {
          this.tasksObj.department = success.data;
        }, (rejected) => {

        });
    } else if (type == 'status') {
      this.tasksObj.status = this.statusOptions.map(o => {
        return o;
      });
    } else if (type == 'priority') {
      this.priorityOptions = this.priorityOptions.map(o => {
        return o;
      });
    }
    else if (type == 'type') {
      this.tasksObj.type = this.typeOptions.map(o => {
        return o;
      });
    }
    else if (type == 'undertaking') {
      this.tasksObj.undertaking = this.undertakingOptions.map(o => {
        return o;
      });
    }
    else {
    }
  }

  /** On Select Option */
  onselectOption(event: any, type: string) {
    if (type == 'department') {
      this.config.where[type] = this.tasksObj.departmentSelected;
    } else if (type == 'priority') {
      this.config.where[type] = this.tasksObj.selectedPriority;
    } else {
      this.config.where[type] = [event];
    }
    setTimeout(() => {
      this.isLoading = true;
      this.pagePayload.page = 0;
      this.table.offset = 0;
      this.config.isLoadingFirstTime = false;
      this.config.start = 0;
      this.getTasksList();
      this.shouldShowResetFilter();
    }, 200);
  }

  /** On clear selection */
  onselectionClear(event: any, type: string) {
    this.config.where[type] = event.isTrusted == true ? [] : event;
    setTimeout(() => {
      this.isLoading = true;
      this.pagePayload.page = 0;
      this.table.offset = 0;
      this.config.isLoadingFirstTime = false;
      this.getTasksList();
      this.shouldShowResetFilter();
    }, 200);
  }

  // On selection remove
  onselectionRemove(event: any, type: string) {
    this.config.where[type] = event.isTrusted == true ? [] : event;
    setTimeout(() => {
      this.isLoading = true;
      this.pagePayload.page = 0;
      this.table.offset = 0;
      this.config.isLoadingFirstTime = false;
      this.getTasksList();
      this.shouldShowResetFilter();
    }, 200);
  }

  // Get project detail
  getProjectsById(id) {
    return new Promise<void>((resolve, reject) => {
      this.tasksObj.projectId = id;
      this.dataService.getDataById('projects/' + id + '/edit')
        .subscribe((success) => {
          this.isEpisodicSeries = success.data.episodic_series;
          this.config.where.isEpisodicSeries = this.isEpisodicSeries;
          resolve();
        }, (error) => {
          if (error.error) {
            var err = error.error;
            this.toastrService.notify('warn', '', err.message);
            this.router.navigate(['/dashboard']);
          }
        });
    });
  }

  // Get column filter
  onColumnFilter(e: any, id: string): void {
    setTimeout(() => {
      this.isLoading = true;
      this.pagePayload.page = 0;
      this.table.offset = 0;
      this.config.isClearFilter = false;
      this.config.isLoadingFirstTime = false;
      this.config.start = 0;
      this.getTasksList();
      this.shouldShowResetFilter();
    }, 200);
  }

  // Create Asset Sidebar open
  openCreateTasks() {
    this._ng_sidebar_data['projectId'] = this.tasksObj.projectId;
    this._ng_sidebar_data['mode'] = 'create';
    this._ng_sidebar_data['is_assign'] = false;
    this._opened = true;
  }


  // Open sidebar function
  onOpened(type) {
    if (type == 'import') {
      this._loadImportSideWindow = true;
    } else if (type == 'manage_col') {
      this._loadManageColSideWindow = true;
    } else {
      this._loadSideWindow = true;
    }
  }


  // On close sidebar function
  onClosed(type) {
    if (type == 'import') {
      this._loadImportSideWindow = false;
    } else if (type == 'manage_col') {
      this._loadManageColSideWindow = false;
    } else {
      this._loadSideWindow = false;
    }
  }

  // On close sidebar function
  closeSideWindow() {
    this.isLoading = true;
    this.pagePayload.page = 0;
    this.table.offset = 0;
    this.config.isLoadingFirstTime = false;
    this.getTasksList();
  }

  // Export asset list
  exportData() {
    this.config.export = true;
    this.isLoading = true;
    this.gridService.openProgressModal();
    this.getTasksList();
  }

  // Export Unassign tasks
  exportUnassigned() {
    this.config.where.assignable = true;
    this.config.where.exportUnassigned = true;
    this.getTasksList();
  }

  // Open Import window
  openImportWindow(type) {
    if (type == 'assignments') {
      this._import_opened = true;
      this._ng_import_type = 'Tasks Assignment';
    } else if (type == 'manual') {
      //this._manual_task_import_opened = true;
      this._import_opened = true;
      this._ng_import_type = 'Manual Tasks';
    } else if (type == 'bulk-transfer') {
      //this._manual_task_import_opened = true;
      this._import_opened = true;
      this._ng_import_type = 'Bulk Task Transfer';
    }

  }

  // Close import side window
  closeImport(event) {
    this._import_opened = false;
    this._loadImportSideWindow = false;
    this.config.where = {};
    this.config.where.project_id = this.tasksObj.projectId;
    this.config.FilterByColumns = {};
    this.getTasksList();
  }

  /** open shot column */
  openManageColWindow() {
    this._ng_sidebar_manage_col_data['column'] = columns('tasks');
    this._ng_sidebar_manage_col_data['key'] = 'task_list_column';
    this._ng_sidebar_manage_col_data['projectId'] = this.tasksObj.projectId
    this._manage_col_opened = true;
  }


  /**close modeal event */
  closeManageCol(value) {
    if (value.reload) {
      this.columnSet().then((response: any) => {
        if (response.status == 'success') {
          this.createColumns();
        }
      }, (err: any) => {
        console.warn("Error while fetching column set")
      });
    }
  }

  /** Get column preferance  */
  columnSet() {
    return new Promise((resolve, rejected) => {
      this.dataService.getListData('task' + '/' + 'task_list_column' + '/preferences', { projectId: this.tasksObj.projectId })
        .subscribe((success) => {
          if (success.data != false) {
            this.datatableService.setParam(success.data, 'columns');
            resolve(success);
          } else {
            resolve(success);
          }
        }, (rjt) => {
          rejected(rjt);
        });
    });
  }

  // Export for bulk tasks tranfer
  exportForBulkTransfer() {
    this.config.where.exportForBulkTransfer = true;
    this.pagePayload.page = 0;
    this.table.offset = 0;
    this.config.isLoadingFirstTime = false;
    this.gridService.openProgressModal();
    this.getTasksList();
  }

  // Open task detail
  onTaskDetailsOpened() {
    this._loadTaskDetailsSideWindow = true;
  }

  // Close task detail
  onTaskDetailsClosed() {
    this._loadTaskDetailsSideWindow = false;
    this._task_details_opened = false;
    this._ng_sidebar_task_details_data = [];
  }


  // Assign the task
  openSideWindowAssign(value: any, type: any) {
    console.log(value);
    this.spinner.hide();
    if ((value.id != undefined && value.id != '')) {
      this._ng_sidebar_data['taskid'] = value.id;
    } else if (value.length > 0) {
      this._ng_sidebar_data['taskid'] = value;
    }
    this._ng_sidebar_data['task'] = true;
    this._ng_sidebar_data['projectId'] = this.tasksObj.projectId;
    this._ng_sidebar_data['department_id'] = value.department_id;
    this._ng_sidebar_data['mode'] = 'edit';
    this._ng_sidebar_data['info'] = value.info;
    this._ng_sidebar_data['auto_created'] = value.auto_created;
    this._ng_sidebar_data['undertaking'] = value.undertaking;
    this._ng_sidebar_data['number'] = value.number;
    this._ng_sidebar_data['name'] = value.name;
    this._ng_sidebar_data['department'] = value.department;
    this._ng_sidebar_data['thumbnail'] = value.thumbnail;
    this._ng_sidebar_data['is_assign'] = true;
    this._ng_sidebar_data['assigned_val'] = value.assigned_to;
    this._ng_sidebar_data['is_multiple_assign'] = false;
    this._opened = true;
  }

  openSideWindowMultiAssign(value: any, row: any) {
    this._ng_sidebar_data['taskid'] = row;
    this._ng_sidebar_data['task'] = true;
    this._ng_sidebar_data['projectId'] = this.tasksObj.projectId;
    this._ng_sidebar_data['department_id'] = 0;
    this._ng_sidebar_data['mode'] = 'edit';
    this._ng_sidebar_data['info'] = '';
    this._ng_sidebar_data['auto_created'] = '';
    this._ng_sidebar_data['undertaking'] = '';
    this._ng_sidebar_data['number'] = '';
    this._ng_sidebar_data['name'] = '';
    this._ng_sidebar_data['department'] = '';
    this._ng_sidebar_data['thumbnail'] = '';
    this._ng_sidebar_data['is_assign'] = true;
    this._ng_sidebar_data['assigned_val'] = 'Assign';
    this._ng_sidebar_data['is_multiple_assign'] = true;
    this._opened = true;
  }

  closeCreateTask($event) {
    this.selected = [];

    // To show changed number of selected items
    const element = this.elementRef.nativeElement.querySelector('.page-count span');
    if (element) {
      element.innerHTML = 0 + ' selected / ';
      // element.innerHTML = selectedRows.length + ' selected / ' + this.totalCount + ' total';
    }
    if ($event.id == 3) {
      this._loadSideWindow = false;
      this._opened = false;
      this._import_opened = true;
      this._ng_import_type = 'Pre / Post / R&D Tasks';
    } else {
      this.isLoading = true;
      this.deleteAllId = [];
      this._ng_sidebar_data['taskid'] = [];
      this.getTasksList();
      if ($event.id == 1) {
        this._loadSideWindow = false;
        this._opened = false;
      }
    }
  }

  // Get Unassigned tasks
  onButtonToggled(value: any, type) {
    this.isLoading = true;
    type == 'unassigned' ? (this.config.where.assignable = value == 'A' ? true : false) : (this.config.where.today = value == 'A' ? true : false);
    this.pagePayload.page = 0;
    this.table.offset = 0;
    this.config.isLoadingFirstTime = false;
    this.getTasksList();
  }

  // Delete Selected Tasks
  deleteAll() {
    if (this.deleteAllId.length > 0) {
      let that = this;
      swal.fire({
        title: 'Delete ' + this.deleteAllId.length + ' ' + 'Task(s)',
        text: "Are you sure you want to delete these " + "Task(s)?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          that.dataService.deleteData('tasks' + 'Destroy', that.deleteAllId)
            .subscribe((success) => {
              this.selected = [];
                // To show changed number of selected items
                const element = this.elementRef.nativeElement.querySelector('.page-count span');
                if (element) {
                  element.innerHTML = 0 + ' selected / ';
                }
              this.pagePayload.page = 0;
              this.table.offset = 0;
              this.config.isLoadingFirstTime = false;
              this.getTasksList();
              that.deleteAllId = [];
            }, (rejected) => {
              // To show changed number of selected items
              const element = this.elementRef.nativeElement.querySelector('.page-count span');
              if (element) {
                element.innerHTML = 0 + ' selected / ';
                // element.innerHTML = selectedRows.length + ' selected / ' + this.totalCount + ' total';
              }
              this.selected = [];
            });

        } else {
          //console.log('cancelled');
        }
      }
      )

    } else {
      swal.fire(
        'Oops...',
        'Please select at least one ' + 'Task' + '.',
        'error'
      );
    }
  }

  // mark off hold
  markAsOffHold() {
    if (this.deleteAllId.length > 0) {
      let that = this;
      swal.fire({
        title: 'Mark ' + this.deleteAllId.length + ' task(s) as Off Hold ',
        text: "Are you sure you want to take these task(s) off hold?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          that.dataService.getOtherData('changeMultiTaskStatus', { 'projectId': that.tasksObj.projectId, 'status': 'Off Hold', 'ids': that.deleteAllId })
            .subscribe((success) => {
              this.selected = [];
                // To show changed number of selected items
                const element = this.elementRef.nativeElement.querySelector('.page-count span');
                if (element) {
                  element.innerHTML = 0 + ' selected / ';
                }
              this.pagePayload.page = 0;
              this.table.offset = 0;
              this.config.isLoadingFirstTime = false;
              this.getTasksList();
              that.deleteAllId = [];
              that.toastrService.notify('success', '', "Task has marked as Off Hold");
              if (success.data.notifications.length > 0) {
                _.forEach(success.data.notifications, function (notification, key) {
                  that.dataService.addNotification(that.clientCode, notification['user_id'], notification);
                });
              }

            }, (rejected) => {

            });

        } else {
          //console.log('cancelled');
        }
      }
      )

    } else {
      swal.fire(
        'Oops...',
        'Please select at least one ' + 'Tasks' + '.',
        'error'
      );
    }
  }

  // Change status

  changeAction(column, value) {
    let fullValue = value == 'NA' ? 'Mark as NA' : 'Revert status';
    let that = this;
    swal.fire({
      title: fullValue + ' ' + this.deleteAllId.length + ' ' + 'Task(s)',
      text: "Are you sure you want to " + fullValue + '?',
      customClass: {content: fullValue=='Revert status' ?"changeTaskStatusPopup":""},
      html: fullValue=='Revert status' ?"Note: Status will not be reverted from Awaiting Inventory, Inventory, WIP, NA, OOP or On Hold!":"",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value) {
        that.dataService.getOtherData('changeMultiTaskStatus', { 'status': value, 'ids': that.deleteAllId, 'projectId': that.tasksObj.projectId })
          .subscribe((success) => {
            this.selected = [];
            // To show changed number of selected items
            const element = this.elementRef.nativeElement.querySelector('.page-count span');
            if (element) {
              element.innerHTML = 0 + ' selected / ';
            }
            if (value == 'NA') {
              that.toastrService.notify('success', '', "Marked as NA");
            } else {
              that.toastrService.notify('success', '', success.message);
            }
            this.pagePayload.page = 0;
            this.table.offset = 0;
            this.config.isLoadingFirstTime = false;
            this.getTasksList();
            that.deleteAllId = [];
          }, (rejected) => {
          });
      } else {
      }
    })
  }

  /**
  Multiple revert status popup
*/
  multipleRevertStatus(column) {
    if (this.deleteAllId.length > 0) {
      let statuObject: any = {};
      this.RevertStatusOptions = this.statusOptions.filter((o: any) => {
        if (o.status == "Awaiting Inventory" || o.status == "Inventory" || o.status == "OOP" || o.status == "NA") {
        } else {
          if (!this.is_external_review && o.status == "Sent to Client") {
          }
          else {
            statuObject[o.id] = o.status;
            return o;
          }
        }

      });

      var inputOptionsPromise: any = new Promise(function (resolve) {
        // get your data and pass it to resolve()
        resolve(statuObject)
      });
      let that = this;
      swal.fire({
        title: 'Change status ' + this.deleteAllId.length + ' ' + 'Task(s)',
        text: "Are you sure you want to Change status?",
        customClass: {content:"changeTaskStatusPopup"},
        html: "Note: Awaiting Inventory, Inventory & WIP task's status can not be changed!",
        // html: "Note: Awaiting Inventory, Inventory & WIP task's status can not be changed!".replace(/\n/g, '<br>'),
        icon: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        input: "select",
        //inputOptions: inputOptionsPromise,
        inputOptions: inputOptionsPromise.__zone_symbol__value,
        inputPlaceholder: 'Select status',
        showCancelButton: true,
        preConfirm: function (value) {
          return new Promise(function (resolve, reject) {
            setTimeout(function () {
              if (value === '') {
                reject('Select Change status from list options')
              } else {
                resolve(value);
              }
            }, 1000)
          })
        },
      }).then((result) => {
        this.selected = [];
          // To show changed number of selected items
          const element = this.elementRef.nativeElement.querySelector('.page-count span');
          if (element) {
            element.innerHTML = 0 + ' selected / ';
          }
        if (result.value) {
          let statusObj: any;
          that.RevertStatusOptions.filter((res: any) => {
            if (res.id == result.value) {
              statusObj = res;
            }
          });
          that.dataService.getOtherData('changeMultiTaskStatus', { 'status': 'change_status', 'ids': that.deleteAllId, 'projectId': that.tasksObj.projectId, 'revertStatus': statusObj })
            .subscribe((success) => {
              if (result.value == 'NA') {
                that.toastrService.notify('success', '', "Only tasks in 'Awaiting Inventory' status are marked as 'NA'");
              }

              if (success.data.notChangeStatusTaskCount > 0) {
                that.toastrService.notify('error', '', "Currently" + ` ${success.data.notChangeStatusTaskCount} ` + "task is active, pause the task to change the status");
              } else {
                that.toastrService.notify('success', '', success.message);
              }
              this.pagePayload.page = 0;
              this.table.offset = 0;
              this.config.isLoadingFirstTime = false;
              this.getTasksList();
              that.deleteAllId = [];
            }, (rejected) => {
            });
        } else {
        }
      }
      )
    } else {
      this.toastrService.notify('error', '', "Please select a task");
    }
  }

  /**
Multiple Bulk tasks status popup
*/
  bulkAssigendTaskDestroy() {
    if (this.deleteAllId.length > 0) {
      let that = this;
      swal.fire({
        title: 'Unassign ' + this.deleteAllId.length + ' task(s) ',
        text: "Are you sure you want to unassign these task(s)?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          that.dataService.getOtherData('bulkAssigendTaskDestroy', { 'projectId': that.tasksObj.projectId, 'ids': that.deleteAllId })
            .subscribe((success) => {
              this.selected = [];
                // To show changed number of selected items
                const element = this.elementRef.nativeElement.querySelector('.page-count span');
                if (element) {
                  element.innerHTML = 0 + ' selected / ';
                }
              this.pagePayload.page = 0;
              this.table.offset = 0;
              this.config.isLoadingFirstTime = false;
              this.getTasksList();
              that.deleteAllId = [];
              //that.toastrService.notify('success', '', success.message);
              if (success.success) {
                that.toastrService.notify('success', '', success.message);
              } else {
                that.toastrService.notify('error', '', success.message);
              }
              if (success.data.notifications.length > 0) {
                //let that = this;
                _.forEach(success.data.notifications, function (notification, key) {
                  that.dataService.addNotification(that.clientCode, notification['user_id'], notification);
                });
              }
            }, (rejected) => {

            });

        } else {
          //console.log('cancelled');
        }
      }
      )

    } else {
      swal.fire(
        'Oops...',
        'Please select at least one ' + 'Tasks' + '.',
        'error'
      );
    }
  }

  actionConvertToAsset(row) {
    let that = this;
    let data = row;
    swal.fire({
      //title: 'Convert ' + row.number + '-' + row.name + ' to Asset? ',
      title: 'Create Asset from this task? ',
      text: "Are you sure you want to create asset from this task?",
      icon: "warning",
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Convert",
      cancelButtonText: "Cancel",
      input: "select",
      //inputOptions: inputOptionsPromise,
      inputOptions: {
        'Character': 'Character',
        'Set': 'Set',
        'Prop': 'Prop'
      },
      inputPlaceholder: 'Select type',
      showCancelButton: true,
      preConfirm: function (value) {
        return new Promise(function (resolve, reject) {
          setTimeout(function () {
            if (value === '') {
              reject('Select Type from list options')
            } else {
              resolve(value);
            }
          }, 1000)
        })
      },
    }).then((result) => {
      if (result.isConfirmed) {
        data.phaseType = result.value;
        data.code = data.number;
        that.dataService.storeData('convertTaskToAsset', data)
          .subscribe((success) => {
            this.selected = [];
              // To show changed number of selected items
              const element = this.elementRef.nativeElement.querySelector('.page-count span');
              if (element) {
                element.innerHTML = 0 + ' selected / ';
              }
            this.pagePayload.page = 0;
            this.table.offset = 0;
            this.config.isLoadingFirstTime = false;
            this.getTasksList();
          }, (rejected) => {

          });
      } else {
        //console.log('cancelled');
      }
    }
    )

  }

  openTaskDetails(data) {
    this._ng_sidebar_task_details_data['projectId'] = this.tasksObj.projectId;
    this._ng_sidebar_task_details_data['project_id'] = this.tasksObj.projectId;
    this._ng_sidebar_task_details_data['from'] = 'task_list';
    this._ng_sidebar_task_details_data['allow_status'] = false;
    this._ng_sidebar_task_details_data['is_artist'] = false;
    this._ng_sidebar_task_details_data['isReview'] = false;
    if (this.currentRole != 'artist' && this.currentRole != 'vendor') {
      this._ng_sidebar_task_details_data['isReview'] = true;
    }

    this._ng_sidebar_task_details_data['selectedTab'] = 'task_info';
    this._task_details_opened = true;

    this._ng_sidebar_task_details_data['task'] = data;
    this._ng_sidebar_task_details_data['task_id'] = data.task_id;
    this._ng_sidebar_task_details_data['actualStatus'] = data.actualStatus;
    this._ng_sidebar_task_details_data['department_id'] = data.department_id;
    this._ng_sidebar_task_details_data['department_tasktype_id'] = data.department_tasktype_id;
    this._ng_sidebar_task_details_data['task_version_id'] = data.task_version_id;
    this._ng_sidebar_task_details_data['id'] = data.task_version_id;
    this._ng_sidebar_task_details_data['status'] = data.status;
    this._ng_sidebar_task_details_data['revision'] = data.revision;
    this._ng_sidebar_task_details_data['version'] = data.version;
    this._ng_sidebar_task_details_data['task_type'] = data.task_type;
    this._ng_sidebar_task_details_data['priority'] = data.priority;
    this._ng_sidebar_task_details_data['working_days'] = data.working_days;
    this._ng_sidebar_task_details_data['bid_days'] = data.bid_days;
    this._ng_sidebar_task_details_data['completion'] = data.completion;
    this._ng_sidebar_task_details_data['undertaking'] = data.undertaking;

    this._ng_sidebar_task_details_data['descArr'] = data.descArr;
    this._ng_sidebar_task_details_data['assigned_to_name'] = data.assigned_to_name;
    this._ng_sidebar_task_details_data['assigned_to'] = data.assigned_to;
    this._ng_sidebar_task_details_data['completionPerArr'] = data.completionPerArr;
    this._ng_sidebar_task_details_data['transferredArr'] = data.transferredArr;
    this._ng_sidebar_task_details_data['layers'] = data.layers;

    this._ng_sidebar_task_details_data['shot_image'] = data.shot_image;
    this._ng_sidebar_task_details_data['asset_image'] = data.asset_image;

    if (data.shot_image && data.shot_image != "") {
      this._ng_sidebar_task_details_data['thumbnail'] = data.shot_image;
    }
    if (data.asset_image && data.asset_image != "") {
      this._ng_sidebar_task_details_data['thumbnail'] = data.asset_image;
    }
    this._ng_sidebar_task_details_data['asset_id'] = data.asset_id;
    this._ng_sidebar_task_details_data['shot_id'] = data.shot_id;
    this._ng_sidebar_task_details_data['task_auto_created'] = data.auto_created;

    this._ng_sidebar_task_details_data['department_name'] = data.department;
    this._ng_sidebar_task_details_data['department_tasktype_name'] = data.department_tasktype_name;
    this._ng_sidebar_task_details_data['info'] = data.info;

    if (data.auto_created == 0 && data.thumbnail != null && data.thumbnail != 'done_all') {
      this._ng_sidebar_task_details_data['thumbnail'] = data.thumbnail;
    } else {
      this._ng_sidebar_task_details_data['icon'] = data.thumbnail;
    }

    this._ng_sidebar_task_details_data['e_episode_id'] = data.e_episode_id;
    this._ng_sidebar_task_details_data['sq_sequence_id'] = data.sq_sequence_id;
  }

  showAdvanceSearch(flag) {
    this.isAdvanceSearchEnabled = !this.isAdvanceSearchEnabled;
  }

  taskAdvanceSearch(query, column) {
    if (column == 'episodes') {
      this.getEpisodes(this.tasksObj.projectId, query);
    } else if (column == 'shots') {
      let sequenceId = 0;
      if (this.tasksSearch.sequence_id != undefined) {
        sequenceId = this.tasksSearch.sequence_id.id;
      }
      this.getShots(query, sequenceId)
    } else if (column == 'sequences') {
      let episodeId = 0;
      if (this.tasksSearch.episode_id != undefined) {
        episodeId = this.tasksSearch.episode_id.id;
      }
      this.getSequences(query, episodeId);
    } else if (column == 'assets') {
      this.getAssets(query);
    } else if (column == 'assignments') {
      this.assignedUnassignedList = this.assignedUnassignedList.map(o => {
        return o;
      });
    } else if (column == 'phases') {
      this.getPhases(query);
    }
  }

  getEpisodes(project_id, query) {
    this.dataService.getListData('episodesItems', { project_id: project_id, 'fromCreateTask': true, 'query': query['query'] })
      .subscribe((success) => {
        this.episodesList = success.data;
      }, (rejected) => {

      });
  }

  getSequences(query, episodeId) {
    this.dataService.getListData('sequencesItems', { project_id: this.tasksObj.projectId, 'fromTasks': true, 'query': query['query'], episodeId: episodeId })
      .subscribe((success) => {
        this.sequenceList = success.data;
      }, (rejected) => {

      });
  }

  getShots(query, sequenceId) {
    this.dataService.getListData('shotsItems', { project_id: this.tasksObj.projectId, 'fromTasks': true, 'query': query['query'], 'sequenceId': sequenceId })
      .subscribe((success) => {
        this.shotList = success.data;
      }, (rejected) => {

      });
  }

  getAssets(query) {
    this.dataService.getListData('assetsItems', { project_id: this.tasksObj.projectId, 'fromTasks': true, 'query': query['query'] })
      .subscribe((success) => {
        this.assetList = success.data;
        this.assetList.unshift({ id: 0, code: 'All' });
      }, (rejected) => {

      });
  }

  getPhases(query) {
    this.dataService.getListData('realtimegetProjectPhases', { 'project_id': this.tasksObj.projectId, 'query': query['query'] })
      .subscribe((success) => {
        this.phaseList = success.data;
      }, (rejected) => {

      });
  }


  //Tasks Advance Search
  onSubmitAdvanceSearch(form: NgForm) {

    this.isAdvanceSearchSubmitted = true;
    if (form.value.shot_id != undefined) {
      this.config.where.shot_id = form.value.shot_id;
    }
    if (form.value.sequence_id != undefined) {
      this.config.where.sequence_id = form.value.sequence_id;
    }
    if (form.value.episode_id != undefined) {
      this.config.where.episode_id = form.value.episode_id;
    }
    if (form.value.asset_id != undefined) {
      this.config.where.asset_id = form.value.asset_id;
    }
    if (form.value.phase_id != undefined) {
      this.config.where.phase_id = form.value.phase_id;
    }
    if (form.value.assignment != undefined) {
      this.config.where.assignment = form.value.assignment;
    }
    this.isAdvanceSearchApplied = true;
    this.config.isLoadingFirstTime = false;
    this.config.isClearFilter = false;
    this.config.isAdvanceSearchApplied = true;
    this.pagePayload.page = 0;
    this.table.offset = 0;
    this.config.isLoadingFirstTime = false;

    let tempTasksSearch = {};

    for (let key in this.tasksSearch) {
      if (this.tasksSearch.hasOwnProperty(key)) {
        tempTasksSearch[key] = this.tasksSearch[key];
      }
    }

    console.log('Payload >>>>>>>>>>>>>>>>>>>>>>>>>>>            ', tempTasksSearch);
    this.dataService.pagePreferancePost(tempTasksSearch, 'task_tabwise_advanceFilter' + '_' + this.tasksObj.activeType);

    // this.storeFilterTabSet();

    this.getTasksList();
    setTimeout(() => {
      this.isAdvanceSearchSubmitted = false;
    }, 2000);
  }

  showCompletionPercentage(data) {
    this.isCompletionOpen = [];
    this.isCompletionOpen[data.id] = true;
    let taskAssignmentIds = data.taskAssignmentIds.split(",");
    let artists = data.assigned_to.split(",");
    let artistsCompletion = data.completion.split(",");

    this.artistsCompletionArr = [];
    let that = this;
    _.forEach(artists, function (value, key) {
      that.artistsCompletionArr.push({
        'project_id': data.project_id,
        'task_id': data.task_id,
        'task_assignment_id': taskAssignmentIds[key],
        'artist': value,
        'completion': artistsCompletion[key]
      });
    });
  }

  artistCompletionCellClick(data, column) {
    if ((this.currentRole == 'artist' || this.currentRole == 'vendor') && this.user.name !== data.artist) {
      return false;
    }
    let taskAssignmentIds = data.task_assignment_id;
    $('.cursor-pointer').removeClass('active');
    $('.' + column + '-' + taskAssignmentIds + '-td').addClass('active');
    $('.' + column + '-' + taskAssignmentIds + '-input input').focus();
    $('.' + column + '-' + taskAssignmentIds + '-input input').val(data[column]);
  }

  artistCompletionCellUpdate(data, column) {
    let dataArr = {
      'projectId': data.project_id,
      'taskId': data.task_id,
      'taskAssignmentId': data.task_assignment_id,
      'completion': data.completion,
    };
    this.dataService.storeData('updateCompletionPercentage', { 'data': dataArr, 'calledFrom': 'tasks' })
      .subscribe((success) => {
        if (success.status == 'success') {
          const index = this.rows.findIndex((col) => {
            return col.task_id == data.task_id;
          });
          this.rows[index]['completion'] = success.completion;
          this.toastrService.notify('success', '', success.message);
          this.getTasksList();
        } else {
          this.toastrService.notify('warn', '', success.message);
        }
      }, (error: any) => {
        error = error.json();
        this.errors = error.error;
        this.toastrService.notify('warn', '', this.errors.completion);
      });

    $('.' + column + '-' + data.task_assignment_id + '-td').removeClass('active');
  }

  formatDate(date: string): string {
    const datePipe = new DatePipe('en-US');
    const formattedDate = datePipe.transform(date, 'yyyy-MM-dd');
    return `<span style="font-weight: bold;">${formattedDate}</span>`;
  }

  formatTime(time: string): string {
    return `<span style="font-weight: 500; color: #999;">${time}</span>`;
  }

  transformDate(value: string): string {
    if (!value) {
      // If the input date is blank or null, return an empty string
      return '';
    }
    const parts = value.split(' ');
    const datePart = parts[0].split('/');
    const timePart = parts[1];
    // Reformat the date to YYYY-MM-DD HH:mm:ss
    const formattedDate = `${datePart[2]}-${datePart[1]}-${datePart[0]} ${timePart}`;
    // Parse the reformatted date string into a JavaScript Date object
    const date = new Date(formattedDate);
    // Define the desired format for the output date
    const format = 'MMM dd, yyyy';
    // Use Angular's DatePipe to format the date
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, format);
  }

  transformTime(value: string): string {
    if (!value) {
      // If the input date is blank or null, return an empty string
      return '';
    }
    const parts = value.split(' ');
    const datePart = parts[0].split('/');
    const timePart = parts[1];
    // Reformat the date to YYYY-MM-DD HH:mm:ss
    const formattedDate = `${datePart[2]}-${datePart[1]}-${datePart[0]} ${timePart}`;
    // Parse the reformatted date string into a JavaScript Date object
    const date = new Date(formattedDate);
    // Define the desired format for the output date
    const format = 'hh:mm:ss a';
    // Use Angular's DatePipe to format the date
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, format);
  }

  shouldShowResetFilter(): boolean {
    // Check conditions for FilterByColumns and where keys
    // const filterByColumns = this.config.FilterByColumns;
    // const where = this.config.where;
    // for (const key in filterByColumns) {
    //   if (filterByColumns.hasOwnProperty(key) && filterByColumns[key] !== null) {
    //     this.isFilterApplied = true;
    //     return true;
    //   }
    // }
    // const validKeys = ['project_id', 'show_all_tasks', 'isEpisodicSeries'];
    // const hasInvalidKey = Object.keys(where).some(key => !validKeys.includes(key));
    // if (hasInvalidKey || this.config.isAdvanceSearchApplied) {
    //   this.isFilterApplied = true;
    //   return true;
    // }
    // this.isFilterApplied = false;
    return false;
  }

  refreshFilter() {
    this.getTasksList();
  }

  clearFilter() {

    this.tasksObj.activeType = 0;
    this.router.navigate([], {
      queryParams: {},
    });

    this.isLoading = true;
    this.isChecked = false;
    this.isTodayChecked = false;
    this.config.FilterByColumns = {
      "department": null,
      "version": null,
      "complexity": null,
      "assigned_to": null,
      "status": null,
      "type": null,
      "undertaking": null
    };

    this.config.sortNew = {};
    this.config.start = 0;
    this.config.sorting.columns = [];
    this.config.filtering.filterString = "";
    this.config.where = {
      "project_id": this.tasksObj.projectId,
      "show_all_tasks": false
    };
    this.config.isLoadingFirstTime = false;
    this.config.isClearFilter = true;
    this.config.isAdvanceSearchApplied = false;
    this.tasksObj.departmentSelected = [];
    this.tasksObj.selectedStatus = [];
    this.tasksObj.selectedComplexity = [];
    this.tasksObj.selectedPriority = [{ id: 'All', name: 'All' }];
    this.tasksSearch = [];
    // Any additional reset logic you might have
    this.pagePayload.page = 0;
    this.table.offset = 0;

    this.selectedTab = 'All';
    // console.log(this.tasksObj.activeType);

    for (let t = 0; t <= 3; t++) {
      this.dataService.pagePreferancePost([], 'task_tabwise_advanceFilter' + '_' + t);
    }
    this.storeFilterTabSet();

    setTimeout(() => {
      if (Object.keys(this.tasksSearch).length === 0 && (this.tasksSearch.length == 0 || this.tasksSearch === Object)) {
        this.isAdvanceSearchSubmitted = false;
      }
    }, 2000);

    this.getTasksList();
  }

  openRescheduleSideWindow(data) {
    this._ng_sidebar_reschedule_data['projectId'] = this.tasksObj.projectId;
    this._ng_sidebar_reschedule_data['task_id'] = data.id;
    this._ng_sidebar_reschedule_data['status'] = data.status;
    this._ng_sidebar_reschedule_data['actualStatus'] = data.actualStatus;
    this._ng_sidebar_reschedule_data['data'] = data;
    this._ng_sidebar_reschedule_data['isExternalReview'] = this.is_external_review;
    this._task_reschedule_opened = true;
  }

  onRescheduleOpenStart() { }

  onRescheduleOpened() {
    this._loadRescheduleSideWindow = true;
  }

  onRescheduleClosed() {
    this._loadRescheduleSideWindow = false;
  }

  /** Re order event */
  reordered(event: any) {
  }

  /** Set bg color */
  setBgColor(data: any, type: string) {

    if (type == 'status') {
      if (data != 'dependency' && data) {
        return data;
      } else if (data == 'dependency' && data) {
        return '#ccb9a1';
      }
    }
    else if (type == 'complexity') {
      if (data == 'Priority') {
        return '#ff6666';
        // return '#fb483a';
      }
      else if (data == 'Very Complex') {
        return '#2b982b';
      }
      else if (data == 'Complex') {
        return '#ff9800';
      }
      else if (data == 'Medium') {
        return '#a5d6a8';
      }
      else { // simple
        return '#bbbbbb';
      }
    }
    else { // type == 'priority'
      if (data == 'High') {
        return '#fb483a';
      }
      else if (data == 'Normal') {
        return '#ff9600';
      }
      else {
        return '#2b982b';
      }
    }
  }

  /**     Change Tab to All/Pre-Post/Asset/Shot       */
  changeActiveType(type: number) {

    this.tasksObj.departmentSelected = [];
    this.tasksObj.selectedStatus = [];
    this.tasksObj.selectedComplexity = [];
    this.tasksObj.selectedType = [];
    this.tasksObj.undertaking = [];
    this.tasksObj.selectedPriority = [{ id: 'All', name: 'All' }];
    this.tasksObj.activeType = type;

    // this.router.navigate([this.commonJs.getClientCodePrefix() + '/projects/' + 'tasks/' + this.tasksObj.projectId], {
    //   queryParams: {
    //     active: type
    //   }
    // });
    switch (type) {
      case 0:
        this.selectedTab = 'All';
        // this.config.where.shot_id = null;
        // this.config.where.phase_id = null;
        // this.config.where.asset_id = null;
        break;
      case 1:
        this.selectedTab = 'Pre/Post';
        // this.config.where.phase_id = { id: 0, code: 'Pre/Post' };
        // this.config.where.shot_id = null;
        // this.config.where.asset_id = null;
        break;
      case 2:
        this.selectedTab = 'Asset';
        // this.config.where.asset_id = { id: 0, code: 'All' };
        // this.config.where.shot_id = null;
        // this.config.where.phase_id = null;
        break;
      case 3:
        this.selectedTab = 'Shot';
        // this.config.where.shot_id = { id: 0, code: 'All' };
        // this.config.where.phase_id = null;
        // this.config.where.asset_id = null;
        break;
    }

    this.config.selectedTab = this.selectedTab;

    this.rows = [];
    this.isLoading = true;

    this.config.where = {
      "project_id": this.tasksObj.projectId,
      "show_all_tasks": false
    };

    this.storeFilterTabSet();

    this.getAdvancedFilterPreferance();
    this.createColumns();
    this.getTasksList();
  }

  getAdvancedFilterPreferance() {
    this.dataService.pagePreferanceGet('task_tabwise_advanceFilter' + '_' + this.tasksObj.activeType).then((resp: any) => {

      // console.log(resp.data, ' <<<<<<<<<<<<<<<<<<<<<<<<<<<< Response');

      this.tasksSearch = resp.data;

    });
  }

  storeFilterTabSet() {

    let subTab = { 0: {}, 1: {}, 2: {}, 3: {} };

    for (let i = 0; i <= 3; i++) {
      if (i == this.tasksObj.activeType) {
        subTab[`${i}`] = this.tasksSearch;
      } else {
        subTab[`${i}`] = {};
      }
    }

    this.dataService.pagePreferancePost({
      activeTab: this.tasksObj.activeType,
      tasksSearch: this.tasksSearch,
      taskSubTabFilters: subTab,
      pageSize: this.pageSize,
      currentPage: this.config.start,
      pageIndex: this.table.offset,
      user_id: JSON.parse(localStorage.getItem('user')).id,
      project_id: this.tasksObj.projectId
    }, 'task_list_page');

  }

  ngOnDestroy() {
    this.pusherService.unsubscribeFromChannel(`tasks`);
  }

  // On datatable body scrolled
  onScrolled(event){

    console.log(event,event['offsetY']);
    console.log(this.previousOffsetY, this.isFullScreen);
    if(event['offsetY'] && (event['offsetY'] != this.previousOffsetY)){
      // console.log(event['offsetY']>this.previousOffsetY);
      this.isFullScreen = event['offsetY']>this.previousOffsetY ? true : false;
      this.dataService.fullScreenView.next(this.isFullScreen);

      this.previousOffsetY = event['offsetY'];
    }
  }

}
