import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { ToastrService } from '../../../services/toastr.service';
import { CommonJs } from '../../../common/common-js';
import { AppConfigs } from '../../../common/app-configs';
import * as moment from 'moment';
import * as _ from "lodash";
import { ExcelmapimportComponent } from '../../../common/excelmapimport/excelmapimport.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription, timer } from 'rxjs';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { ConfirmationPopupComponent } from 'src/app/common/confirmation-popup/confirmation-popup.component';

declare var $: any;

@Component({
  selector: 'create-task',
  templateUrl: './create-task.component.html',
  styleUrls: ['./create-task.component.css']
})
export class CreateTaskComponent implements OnInit {
  bsModalRef: BsModalRef;

  entityName = 'Tasks';
  entityNameSingular = ' Task';
  apiRoutePrefix = 'tasks';
  projectDetailsRoute = 'project_details';
  dirName = 'tasks';
  attachmentDirName = '/task_assignment_attachment';
  apiRoutePrefixListItem = 'listItem';
  phaseDepartmentroute = 'department';
  apiRoutePrefixChecklist = 'checklist';

  @Input('attr.data') dataHtml: any;
  @ViewChild('CloseOtherWindow', { static: false }) CloseOtherWindow: ElementRef;
  @ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;
  @ViewChild('fmTask', { static: false }) fmTask: NgForm;
  @Output() closeCreateTask = new EventEmitter();

  data: any;
  myform: any;
  onClose: any;
  tasks: { [index: string]: any } = {};
  errors: { [index: string]: any } = {};

  focused: string = '';
  SaveButtonText: string = 'SAVE';
  isMessgaeMode: any;
  settingsAll: any = JSON.parse(localStorage.getItem('settings'));
  hideundertaking: boolean;
  isDeleteMode: boolean = false;
  hideUsers: boolean = false;
  taskId: number;
  deleteId: number;
  deleteName: string = '';
  isDisplyFrm: boolean = false;
  alertMessage: string = '';
  DeleteButtonText: string = 'UNASSIGN';
  DateFormat: any;
  /*  type:number=0;*/
  types: Array<any> = [{ "id": 0, "name": "R&D" }, { "id": 1, "name": "Production" }];
  public endDate = {
    singleDatePicker: true,
    autoUpdateInput: true,
    showDropdowns: true,
    timePicker: true,
    //timePickerIncrement: 5,
    autoApply: true,
    drops: 'up',
    locale: {
      format: 'DD MMM YYYY hh:mm A'
      //format: 'DD MMM YYYY'+ ' 23:59'
    }
  };

  public startDate = {
    singleDatePicker: true,
    autoUpdateInput: true,
    showDropdowns: true,
    //timePickerIncrement: 5,
    timePicker: true,
    autoApply: true,
    drops: 'up',
    "maxDate": "31 Dec 2030",
    locale: {
      format: 'DD MMM YYYY hh:mm A'
      //format: 'DD MMM YYYY'+ ' 00:00'
    }
  };
  project: { [index: string]: any } = {};
  projectId = '';
  status: Array<any> = [];
  departments: Array<any> = [];
  tasktypes: Array<any> = [];
  phases: Array<any> = [];
  episodes: Array<any> = [];
  sequences: Array<any> = [];
  asset_shot: Array<any> = [];
  assigned_to: Array<any> = [];
  department_tasktypes: Array<any> = [];
  department = '';
  priority: Array<any> = this.appConfigs.task_priority;
  undertaking: Array<any> = this.appConfigs.task_undertaking;
  isDisabled: boolean = false;
  isSequenceDisabled: boolean = false;
  istransfer: number;
  shotPath = this.commonJs.getUploadUrl() + 'shots/';
  assetPath = this.commonJs.getUploadUrl() + 'assets/';
  showIcon: boolean = false;
  iconVal = '';
  isAssign: boolean = false;
  isAssignList: boolean = false;
  assigned_users: Array<any> = [];
  assigned_users1: Array<any> = [];
  assigned_task: Array<any> = [];
  isTransfer: boolean = false;
  transfered_to: Array<any> = [];
  task_transfer: Array<any> = [];
  isDisableAssign: boolean = false;
  isStatusDisabled: boolean = true;
  isCloseSidebar: boolean = false;

  tempType = '';
  isEpisodicSeries: boolean = false;
  isTaskAssignSubmitted: boolean = false;

  clientCode = localStorage.getItem('access_code');
  userId = localStorage.getItem('userId');

  lastAssignment = [];
  lastAssignmentStatus: string;

  isTaskSaveDisabled: boolean = false;
  isLocationBasedTaskAssignments: boolean = false;

  url: any;
  thumbnail_preview_src: string = '';
  thumbnail: string;
  thumbnail_name: string;
  thumbnail_type: string;
  thumbnail_old: string;
  uploadUrl = this.commonJs.getUploadUrl();

  noUsersAddedInTeam: boolean = false;
  noSequencesFound: boolean = false;
  noEpisodesFound: boolean = false;


  //Docs
  attachmentUrl: any;
  attachment_preview_src: any;
  attachment: string;
  attachment_type: string;
  attachment_old: string;
  multipleFiles: Array<any> = [];
  TempMultipleFiles: Array<any> = [];
  attachment_display_name: string;
  isFirstTime: boolean = true;
  attachmentArr = [];
  invalidAttach: boolean = false;
  private timer: Observable<any>;
  private subscription: Subscription;
  attachmentFile = './assets/images/default/attechment.png';
  isAttechment: boolean = false;
  public files: NgxFileDropEntry[] = [];
  selectedBidType: {name:string} = {name:'Day'};
  bidTypes:Array<any> = [ {name:'Day'},{name:'Hour'}];

  constructor(
    private dataService: DataService,
    private toastrService: ToastrService,
    public commonJs: CommonJs,
    public appConfigs: AppConfigs,
    private modalService: BsModalService,
    private cd: ChangeDetectorRef) { }

  checklistData = [];

  isTaskPermissions = [];

  ngOnInit() {
    // console.log('Task opened');
    this.hideundertaking = true;
    if (this.dataHtml != undefined) {
      this.data = this.dataHtml;
      // console.log(this.data);
      this.tasks.id = this.data.taskid;
      this.tasks.taskid = this.data.taskid;
    }
    if (this.data.is_assign) {
      if (this.data.assigned_val == 'Assign') {
        this.isAssign = true;
        this.isAssignList = false;
        this.isCloseSidebar = true;
        //this.endDate.locale.format = moment().format('DD MMM YYYY hh:mm');
      } else {
        this.isAssignList = true;
        this.isAssign = false;
      }
      this.getAssignedUsers();
      this.getTransferHistory();
    }
    this.projectId = this.data.projectId;
    this.DateFormat = this.settingsAll.date_format;
    this.startDate.locale.format = 'DD MMM YYYY hh:mm A';
    //this.endDate.locale.format = 'DD MMM YYYY hh:mm A';
    this.tasks = {
      project_id: this.projectId,
      name: '',
      bid_days: '',
      type: null,
      //end_date:moment().format('DD MMM YYYY hh:mm A'),
      status: []
    }
    //$.AdminBSB.input.activate();
    this.subngOnInit();
    this.getProjectsById(this.projectId);

    this.isTaskPermissions['task_assignment_add'] = this.commonJs.checkAccess('task_assignment', 'add', { 'baiPass': ['admin'] }, this.projectId);
    this.isTaskPermissions['task_assignment_view'] = this.commonJs.checkAccess('task_assignment', 'view', { 'baiPass': ['admin'] }, this.projectId);
    this.isTaskPermissions['task_assignment_edit'] = this.commonJs.checkAccess('task_assignment', 'edit', { 'baiPass': ['admin'] }, this.projectId);
    this.isTaskPermissions['task_assignment_delete'] = this.commonJs.checkAccess('task_assignment', 'delete', { 'baiPass': ['admin'] }, this.projectId);
  }

  ngAfterViewInit() {
    $.AdminBSB.input.activate();
  }

  subngOnInit() {
    this.tasks = {
      name: '',
      bid_days: '',
      status: [],
      asset_shot: [],
      type: null,
      thumbnail: '',
      thumbnail_name: '',
      thumbnail_type: '',
      thumbnail_old: '',
      thumbnail_display_name: '',
    }
    this.tasks.status = { id: "Inventory", name: "Inventory" };
    if (this.data.mode == 'create') {
      //this.tasks.start_date = moment().format('DD MMM YYYY hh:mm A');
      this.tasks.undertaking = "In House";
      this.tasks.priority = "Normal";
      this.tasks.asset_shot.subtype = '';
      //this.tasks.end_date = moment().format('DD MMM YYYY hh:mm A');
      this.tasks.type = 1;

      //this.projects.end_date = moment().format(this.DateFormat);

    }
    if (this.data.taskid && (this.data.mode == 'edit' || this.data.mode == 'view')) {

      this.focused = "focused";
      this.isDisabled = true;
      this.isSequenceDisabled = true;
      this.dataService.getDataById(this.apiRoutePrefix + '/' + this.data.taskid + '/edit')
        .subscribe((success) => {

          this.tasks = success.data;
          this.tasks.id = this.data.taskid;
          this.tasks.taskid = this.data.taskid;

          this.tasks.bid_days = 1;
          //this.tasks.assigned_to = {id: 18, name: "Nikhil"};

          //this.tasks.end_date = moment().format('DD MMM YYYY hh:mm A');
          this.showIcon = true;
          this.iconVal = this.tasks.asset_shot_type;
          if (!this.tasks.status) {
            this.tasks.status = { id: "Inventory", name: "Inventory" };
          }

          if (!this.tasks.undertaking) {
            this.tasks.undertaking = "In House";
          }
          if (!this.tasks.priority) {
            this.tasks.priority = "Normal";
          }

          if (this.tasks.start_date) {
            this.startDate['startDate'] = moment(this.tasks.start_date).format('DD MMM YYYY hh:mm A');
            this.tasks.start_date = moment(this.tasks.start_date).format('DD MMM YYYY hh:mm A');
          } else {
            this.tasks.start_date = moment().set({ hour: 10, minute: 0, second: 0, millisecond: 0 }).format('DD MMM YYYY hh:mm A');
            this.startDate['startDate'] = moment().set({ hour: 10, minute: 0, second: 0, millisecond: 0 }).format('DD MMM YYYY hh:mm A');
            this.tasks.start_date = moment().format('DD MMM YYYY hh:mm A');
            this.startDate['startDate'] = moment().format('DD MMM YYYY hh:mm A');
          }

          if (this.tasks.end_date) {
            this.endDate['endDate'] = moment(this.tasks.end_date).format('DD MMM YYYY hh:mm A');
            this.tasks.end_date = moment(this.tasks.end_date).format('DD MMM YYYY hh:mm A');
          } else {
            // this.endDate['startDate'] = moment().add(1, 'days').set({ hour: 10, minute: 0, second: 0, millisecond: 0 }).format('DD MMM YYYY hh:mm A');
            // this.tasks.end_date = moment().add(1, 'days').set({ hour: 10, minute: 0, second: 0, millisecond: 0 }).format('DD MMM YYYY hh:mm A');
            this.endDate['startDate'] = moment().add(1, 'days').format('DD MMM YYYY hh:mm A');
            this.tasks.end_date = moment().add(1, 'days').format('DD MMM YYYY hh:mm A');
          }

          if (this.tasks.thumbnail) {
            this.thumbnail_old = this.tasks.thumbnail;
            let fileExtension = this.tasks.thumbnail.split('.').pop();
            if (fileExtension != 'png' && fileExtension != 'jpg' && fileExtension != 'jpeg' && fileExtension != 'gif') {
              this.thumbnail_preview_src = './assets/images/default/attechment.png';
            } else {
              this.thumbnail_preview_src = this.uploadUrl + this.dirName + '/' + this.tasks.thumbnail;
            }
          }
          if (this.tasks.thumbnail_display_name) {
            this.thumbnail_name = this.tasks.thumbnail_display_name;
          }

        }, (rejected) => {

          this.SaveButtonText = "SAVE"
        });


      //this.getData();
    }
  }

  search(query, column) {
    if (this.data.is_multiple_assign) {
      this.data.taskid = this.data.taskid;
      this.tasks.taskid = this.data.taskid;
    } else if (this.tasks.task_id != undefined) {
      this.data.taskid = this.tasks.task_id;
    } else {
      this.data.taskid = this.data.taskid;
    }
    if (column == 'phases') {
      this.dataService.getListData('getProjectPhase', { 'project_id': this.projectId, 'query': query['query'] })
        .subscribe((success) => {
          this.phases = success.data;
        }, (rejected) => {

        });
    } else if (column == 'departments') {
      if (this.tasks.phase_id != null) {
        let subtype = '';
        if (this.tasks.asset_shot.subtype && this.tasks.asset_shot.subtype != undefined && this.tasks.asset_shot.subtype != '') {
          subtype = this.tasks.asset_shot.subtype;
        }

        this.dataService.getListData('getprojectDepartment', { 'phaseID': this.tasks.phase_id['id'], 'project_id': this.projectId, 'query': query['query'] })
          .subscribe((success) => {
            this.departments = success.data;
            if (this.tasks.phase_id['type'] == 'pre' || this.tasks.phase_id['type'] == 'post') {
              this.departments.unshift({ department_id: 0, name: 'All' });
            }
          }, (rejected) => {

          });
      }
      else {
        this.departments = null;
      }
    } else if (column == 'asset_shot') {
      this.dataService.getListData('getAssetShot', { 'project_id': this.projectId, 'department_id': this.tasks.department_id.department_id, 'query': query['query'] })
        .subscribe((success) => {
          this.asset_shot = success.data;
        }, (rejected) => {

        });
    } else if (column == 'status') {
      this.status = this.appConfigs.sht_ast_status.map(v => { return v });
    } else if (column == 'assigned_to') {
      this.istransfer = 0;
      if (this.tasks.undertaking == 'In House') {
        this.getTaskAssignableUsers(this.tasks.taskid?this.tasks.taskid:this.data.taskid, this.data.department_id, query, '', 'assign', this.data.is_multiple_assign);
      } else {
        this.getTaskAssignableUsers(this.tasks.taskid?this.tasks.taskid:this.data.taskid, this.data.department_id, query, 'Outsourced', 'assign', this.data.is_multiple_assign);
      }
    } else if (column == 'transfered_to') {
      if (this.tasks.undertaking == 'In House') {
        this.getTaskTransferableUsers(this.data.taskid, this.data.department_id, query, '', 'transfer');
      }
      else {
        this.getTaskTransferableUsers(this.data.taskid, this.data.department_id, query, 'Outsourced', 'transfer');
      }
    } else if (column == 'episodes') {
      this.dataService.getListData('episodesItems', { project_id: this.projectId, 'fromCreateTask': true, 'query': query['query'] })
        .subscribe((success) => {
          this.episodes = success.data;
          if (this.episodes.length == 0) {
            this.noEpisodesFound = true;
          }
        }, (rejected) => {

        });
    } else if (column == 'sequences') {
      this.dataService.getListData('sequencesItems', { project_id: this.projectId, 'fromCreateTask': true, 'query': query['query'] })
        .subscribe((success) => {
          this.sequences = success.data;
          if (this.sequences.length == 0) {
            this.noSequencesFound = true;
          }
        }, (rejected) => {

        });
    } else if (column == 'bidType') {
      this.bidTypes = [...this.bidTypes];
    }
  }
  /* onChange(flag){

     if(flag) {
     this.type = 1;
     } else {
     this.type = null;
     }
   }*/
  onUtChange(undertaking, flag, form: NgForm) {
    if (!this.isTransfer) {
      this.tasks.assigned_to = null;
    } else {
      this.tasks.transfered_to = null;
    }
    if (flag) {
      this.tasks.undertaking = undertaking;


    } else {
      this.tasks.undertaking = "In House";
    }


    if (this.isTransfer && this.tasks.undertaking == "In House") {
      this.bidDaysEnter('test')
    }
  }



  clearDepartment() {
    this.tasks.department_id = null;
  }

  onSubmit(form: NgForm) {
    this.isTaskSaveDisabled = true;

    if (form.value.number == undefined) {
      form.value.number = null;
    }

    if (this.url != undefined) {
      form.value.thumbnail = this.url;
    }
    form.value.thumbnail_name = this.thumbnail_name;
    form.value.thumbnail_old = this.thumbnail_old;

    if (form.value.department_id) {
      form.value.department_id = this.tasks.department_id.department_id;
      form.value.department_tasktype_id = this.tasks.department_id.department_tasktypes_id;
    }
    if (this.tasks.phase_id) {
      form.value.phase_id = this.tasks.phase_id;
      form.value.prod_type = this.tasks.phase_id.type;
    }

    if (this.isEpisodicSeries) {
      if (this.tasks.episode_id.id) {
        form.value.season_id = this.tasks.episode_id.season_id;
        form.value.episode_id = this.tasks.episode_id.id;
        form.value.episode_number = this.tasks.episode_id.episode_number;
      } else {
        form.value.episode_id = 0;
      }
    }

    form.value.isEpisodicSeries = this.isEpisodicSeries;

    if (form.value.sequence_id) {
      form.value.sequence_id = this.tasks.sequence_id.id;
      form.value.sequence_number = this.tasks.sequence_id.sequence_number;

    } else {
      form.value.sequence_id = 0;
    }

    if (this.tasks.type == null || this.tasks.type == undefined) {
      form.value.type = null;
    }

    this.SaveButtonText = 'SAVING';
    form.value.project_id = this.projectId;

    if (this.data.taskid && this.data.mode == 'edit') {
      form.value.id = this.data.projectid;
      form.value.department_id = this.tasks.department_id.id;
      form.value.department_tasktype_id = this.tasks.department_id.department_tasktype_id;
      if (this.isEpisodicSeries) {
        form.value.season_id = this.tasks.episode_id.season_id;
        form.value.episode_id = this.tasks.episode_id.id;
      } else {
        form.value.sequence_id = this.tasks.sequence_id.id;
      }
      if (this.tasks.asset_id != 0) {
        form.value.asset_id = this.tasks.asset_id;
      }
      if (this.tasks.shot_id != 0) {
        form.value.shot_id = this.tasks.shot_id;
      }


      this.dataService.updateData(this.apiRoutePrefix + '/' + this.data.taskid, form.value)
        .subscribe((success) => {
          // Save effect within button
          this.SaveButtonText = 'SAVED';
          setTimeout(() => {
            this.SaveButtonText = 'SAVE';
            this.CloseOtherWindow.nativeElement.click();
            this.close(2);
          }, 2);
          this.isTaskSaveDisabled = false;

        }, (error) => {
          if (error.error) {
            var err = error.error;
            this.errors = err.error;
            this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
            this.SaveButtonText = 'SAVE';
          }
          this.isTaskSaveDisabled = false;
        });
    } else {
      this.dataService.storeData(this.apiRoutePrefix, form.value)
        .subscribe((success) => {
          setTimeout(() => {
            this.SaveButtonText = 'SAVE';
            this.CloseOtherWindow.nativeElement.click();
            this.close(2);
          }, 2);
          this.isTaskSaveDisabled = false;
          this.toastrService.notify(success.status, '', success.message);
        }, (error) => {
          if (error.error) {
            this.SaveButtonText = 'SAVE';
            var err = error.error;
            this.errors = err;
            this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
          }
          this.isTaskSaveDisabled = false;
        });
    }
  }

  onAssign(form: NgForm) {
    this.isTaskAssignSubmitted = true;
    this.hideundertaking = true;
    this.SaveButtonText = 'SAVING';
    if (form.value.assigned_to) {
      form.value.assigned_to = this.tasks.assigned_to.id;
    }

    if ($('#start_date').val() != '' && $('#start_date').val() != undefined) {
      form.value.start_date = moment($('#start_date').val(), 'DD MMM YYYY hh:mm A').format('YYYY-MM-DD HH:mm:ss');
    } else {
        form.value.start_date = null;
    }
    if ($('#end_date').val() != '' && $('#end_date').val() != undefined) {
        form.value.end_date = moment($('#end_date').val(), 'DD MMM YYYY hh:mm A').format('YYYY-MM-DD HH:mm:ss');
    } else {
        form.value.end_date = null;
    }

    if (form.value.status) {
      form.value.status = this.tasks.status.id;
    }

    if (form.value.bid_days) {
      form.value.bid_days = (this.selectedBidType['name'] && this.selectedBidType['name'] =='Hour')?(form.value.bid_days/8):form.value.bid_days;
    } else {
      form.value.bid_days = 0;
    }
    // if (form.value.bid_days) {
    //   if(this.selectedBidType['name']){
    //     form.value.bid_days = [form.value.bid_days ,  this.selectedBidType['name']=='Minutes'? 1 : 0 ];
    //     var arr = "";
    //   _.forEach(form.value.bid_days, function (value) {
    //     arr += value;
    //     arr += "|";
    //   });
    //   form.value.bid_days = arr.slice(0, -1);
    //   }
    // }

    form.value.project_id = this.projectId;
    if (this.data.is_multiple_assign) {
      form.value.task_id = this.data.taskid;
    } else if (this.tasks.task_id != undefined) {
      form.value.task_id = this.tasks.task_id;
    }
    else if (this.taskId) {
      form.value.task_id = this.taskId;
    } else if (this.data.taskid) {
      form.value.task_id = this.data.taskid;
    }
    if (form.value.undertaking == undefined) {
      form.value.undertaking = this.tasks.undertaking;
    }
    form.value.is_multiple_assign = this.data.is_multiple_assign;
    if (this.data.taskid && this.tasks.task_assignment_id && this.tasks.task_assignment_id != '' && !this.isTransfer) {
      form.value.assigned_to = this.tasks.assigned_to.id;
      this.dataService.updateData('taskAssignUpdate/' + this.tasks.task_assignment_id, form.value)
        .subscribe((success) => {
          setTimeout(() => {
            this.SaveButtonText = 'SAVE';
            this.close(2);
          }, 2);

          this.isAssign = false;
          this.isAssignList = true;
          this.tasks = [];
          this.getAssignedUsers();
          this.getTransferHistory(form.value.task_id);
          this.errors = [];
          this.isTaskAssignSubmitted = false;
          this.tasks['id'] = form.value.task_id;
          this.tasks['taskid'] = form.value.task_id;
          this.tasks['task_id'] = form.value.task_id;
        }, (error) => {
          if (error.error) {
            var err = error.error;
            this.errors = err.errors;
            this.SaveButtonText = 'SAVE';
            this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
            this.isTaskAssignSubmitted = false;
          }
        });
    } else if (this.isTransfer) {
      form.value.assigned_to = this.tasks.assigned_to.id;
      if (form.value.transfered_to) {
        //form.value.transfered_to= this.tasks.status.id;
        form.value.to_user_id = this.tasks.transfered_to.id;
      }
      form.value.from_user_id = this.tasks.assigned_to.id;
      form.value.task_assignment_id = this.tasks.task_assignment_id;
      form.value.status = this.tasks.status.id;
      this.dataService.updateData('assignedTaskTransfer/' + this.tasks.task_assignment_id, form.value)
        .subscribe((success) => {
          setTimeout(() => {
            this.SaveButtonText = 'SAVE';
            this.close(2);
          }, 2);
          this.isAssign = false;
          this.isAssignList = true;
          this.isTransfer = false;
          this.tasks = [];
          this.getAssignedUsers();
          this.getTransferHistory(form.value.task_id);
          this.errors = [];
          this.isTaskAssignSubmitted = false;
          if (success.notifications.length > 0) {
            let that = this;
            _.forEach(success.notifications, function (notification, key) {
              that.dataService.addNotification(that.clientCode, notification['user_id'], notification);
            });
          }
        }, (error) => {
          if (error.error) {
            var err = error.error;
            this.errors = err.errors;
            this.SaveButtonText = 'SAVE';
            this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
            this.isTaskAssignSubmitted = false;
          }
        });
    } else {
      form.value.status = this.tasks.status.id;
      if (this.multipleFiles.length > 0) {
        form.value.attachmentArr = this.multipleFiles;
      }
      form.value.task_id = form.value.task_id ? form.value.task_id : this.taskId;

      this.dataService.storeData('taskAssign', form.value)
        .subscribe((success) => {
          setTimeout(() => {
            this.SaveButtonText = 'SAVE';
            this.close(1);
          }, 2);

          // let notification = {
          //   client_code: this.clientCode,
          //   created_by: this.userId,
          //   description: 'task assigned',
          //   is_unread: true,
          //   project_id: 1,
          //   task_id: 1,
          //   type: 'assign_task',
          //   user_id: this.tasks.assigned_to.id
          // }

          if(success.status=='error'){
            this.toastrService.notify('warn', '',success.message);
            return;
          }

          if (success.data.notifications.length > 0) {
            let that = this;
            _.forEach(success.data.notifications, function (notification, key) {
              that.dataService.addNotification(that.clientCode, notification['user_id'], notification);
            });
          }
          //this.dataService.addNotification(this.clientCode,this.tasks.assigned_to.id,notification);

          this.isAssign = false;
          this.isAssignList = true;
          if (success.errorMessage) {
            this.toastrService.notify('info', '', success.errorMessage);
          }
          //this.getAssignedUsers();
          this.getTransferHistory(form.value.task_id);
          this.errors = [];
          this.isTaskAssignSubmitted = false;
        }, (error) => {
          if (error.error) {
            var err = error.error;
            this.errors = err.errors;
            this.SaveButtonText = 'SAVE';
            this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
            this.isTaskAssignSubmitted = false;
          }
        });
    }
  }

  getAssignedUsers() {
    // this.dataService.getListData('getAssignedUsers/'+this.data.taskid+'','')
    // .subscribe((success) => {
    // 	this.assigned_users = success.data;
    // }, (rejected) => {
    //
    // });
  }
  getTransferHistory(taskId = null) {
    //this.isAssignList = true;
    //this.isAssign = false;
    if (taskId != null) {
      this.data.taskid = taskId;
    }
    this.isTransfer = false;
    this.isDeleteMode = false;
    this.dataService.getListData('getTransferHistory/' + this.data.taskid, '')
      .subscribe((success) => {
        this.assigned_users1 = success.data;
        this.lastAssignment = this.assigned_users1[0];
        if (this.lastAssignment != undefined && this.lastAssignment['actualStatus'] != null) {
          this.lastAssignmentStatus = this.lastAssignment['actualStatus'];
        } else if (this.lastAssignment != undefined && this.lastAssignment['actualStatus'] == null && this.lastAssignment['actualTtlStatus'] != null) {
          this.lastAssignmentStatus = this.lastAssignment['actualTtlStatus'];
        }
      }, (rejected) => {

      });
  }
  getAssignedTask(id, type, version) {
    this.tempType = type;
    if (type != 'transfer') {
      this.hideundertaking = false;
    } else {
      this.hideundertaking = true;
    }
    this.isDisableAssign = true;
    this.isStatusDisabled = false;

    if (type == 'transfer') {
      this.tasks.priority = "Normal";
      this.isTransfer = true;
      this.tasks.end_date = moment(this.tasks.end_date).format('DD MMM YYYY hh:mm A');
      this.errors = [];
    }
    this.dataService.getDataById('getAssignedTask/' + id + '/edit')
      .subscribe((success) => {
        this.tasks = success.data;
        if (this.tasks.thumbnail) {
          this.isAttechment = true;
          this.multipleFiles.push({
            attachment: this.tasks.thumbnail,
            attachment_display_name: this.tasks.thumbnail_display_name,
            attachment_preview_src: this.tasks.thumbnail_display_name != null ? (this.uploadUrl + this.attachmentDirName + '/attachments/' + this.tasks.thumbnail) : '',
            ext: this.tasks.thumbnail != null ? this.tasks.thumbnail.split('.').pop().toLowerCase() : ''
          });

        }


        this.tasks.task_id = success.data.task_id;
        this.tasks.task_assignment_id = id;
        this.isAssign = true;
        this.isAssignList = false;
        this.tasks.version = version;
        if (this.tasks.start_date) {
          this.startDate['startDate'] = moment(this.tasks.start_date).format('DD MMM YYYY hh:mm A');
          this.tasks.start_date = moment(this.tasks.start_date).format('DD MMM YYYY hh:mm A');
        } else {
          this.tasks.start_date = moment().format('DD MMM YYYY hh:mm A');
        }
        if (this.tasks.end_date) {
          this.endDate['startDate'] = moment(this.tasks.end_date).format('DD MMM YYYY hh:mm A');
          this.tasks.end_date = moment(this.tasks.end_date).format('DD MMM YYYY hh:mm A');
        } else {
          this.tasks.end_date = moment().format('DD MMM YYYY hh:mm A');
        }
        this.focused = 'focused';

      }, (rejected) => {

      });
  }

  deleteAssignedTask(event,template: TemplateRef<any>, task, id, name) {
    event.stopPropagation();
    this.taskId = task.task_id;
    this.deleteId = id;
    this.deleteName = name;
    // this.isDeleteMode = true;
    // this.isAssign = false;
    // this.isAssignList = false;

    // const config = {
    //   initialState: {
    //     title: 'Unassign Task',
    //     message: "Are you sure you want to unassign Task from "+ name +" ?",
    //   },
    //   class: 'modal-md custom-modal-css',
    //   centered: true,
    //   confirmationBtnText: 'Unassign',
    //   animated: false
    // };
    this.bsModalRef = this.modalService.show(template);

    // this.bsModalRef.content.event.subscribe((res: any) => {
    //   console.log(res);
    // });

  }

  deleteAssignedTaskAction(id, taskId) {
    let that = this;
    this.DeleteButtonText = 'UNASSIGNING';
    this.dataService.deleteData('assigendTaskDestroy', { 'projectId': this.projectId, 'id': id })
      .subscribe((success) => {
        that.DeleteButtonText = 'UNASSIGNED';
        setTimeout(() => {
          that.DeleteButtonText = "UNASSIGN";
          that.isDeleteMode = false;
          that.isAssign = false;
          that.isAssignList = true;
          //this.getAssignedUsers();
          this.getTransferHistory(taskId);
          this.data.taskid = taskId;
          //that.subngOnInit();
          this.close(2);
          this.bsModalRef.hide();
        }, 2);

        if (success.data.notifications.length > 0) {
          let that = this;
          _.forEach(success.data.notifications, function (notification, key) {
            that.dataService.addNotification(that.clientCode, notification['user_id'], notification);
          });
        }

      }, (rejected) => {
        if (rejected.error) {
          var err = rejected.error;
          //console.log(err);
          this.errors = err.error;
          this.DeleteButtonText = "UNASSIGN";
          this.toastrService.notify('warn', '', err.message);
        }
      });
  }

  assignTaskForm() {
    this.tempType = 'add';
    this.hideundertaking = true;
    this.isAssign = true;
    this.isAssignList = false;
    this.isDisableAssign = false;
    this.tasks.bid_days = '';
    //this.startDate.locale.format = this.DateFormat;
    //this.startDate.locale.format = moment().format('DD MMM YYYY hh:mm');
    //this.endDate.locale.format = moment().format('DD MMM YYYY hh:mm');
    this.tasks.start_date = moment().format('DD MMM YYYY hh:mm A');
    this.tasks.end_date = moment().format('DD MMM YYYY hh:mm A');
    //this.tasks.end_date = '';
    this.tasks.assigned_to = [];
    this.tasks.status = { id: "Inventory", name: "Inventory" };
    this.tasks.undertaking = "In House";
    this.tasks.priority = "Normal";
    this.errors = [];
    //this.tasks.start_date = moment().format(this.DateFormat);
  }

  backToCreate(event) {
    event.stopPropagation();
    this.isAssign = true;
    this.isAssignList = false;
  }

  toggleFrm() {
    if (this.assigned_users.length < 0) {
      this.CloseOtherWindow.nativeElement.click();
    }
    this.isAssignList = true;
    this.isAssign = false;
    this.isDeleteMode = false;
    this.isTransfer = false;
    this.tasks = [];
  }

  getTaskTransferableUsers(id, department_id, query = '', outsource, activity) {
    if (outsource == 'Outsourced') {
      this.dataService.getListData('getTaskTransferableUsers', { 'project_id': this.projectId, 'task_id': id, 'department_id': department_id, 'query': query['query'], 'outsource': outsource })
        .subscribe((success) => {
          this.transfered_to = success.data;
          this.transfered_to = this.transfered_to.filter(user => user.id !== this.tasks.assigned_to.id);
        }, (rejected) => {

        });
    }
    else {
      this.dataService.getListData('getTaskTransferableUsers', { 'project_id': this.projectId, 'task_id': id, 'department_id': department_id, 'query': query['query'], 'outsource': outsource, 'isLocationBasedTaskAssignments': this.isLocationBasedTaskAssignments })
        .subscribe((success) => {
          this.transfered_to = success.data;
          this.transfered_to = this.transfered_to.filter(user => user.id !== this.tasks.assigned_to.id);
        }, (rejected) => {

        });
    }

  }

  getTaskAssignableUsers(id, department_id, query = '', undertaking = '', activity, is_multiple_assign = false) {
    if (undertaking == 'Outsourced') {
      this.dataService.getListData('getTaskAssignableUsers', { 'project_id': this.projectId, 'task_id': id, 'department_id': department_id, 'query': query['query'], 'undertaking': undertaking, 'is_multiple_assign': is_multiple_assign })
        .subscribe((success) => {
          this.assigned_to = success.data;
          if (this.assigned_to.length == 0) {
            this.noUsersAddedInTeam = true;
          }
        }, (rejected) => {

        });
    } else {
      this.dataService.getListData('getTaskAssignableUsers', { 'project_id': this.projectId, 'task_id': id, 'department_id': department_id, 'query': query['query'], 'undertaking': '', 'is_multiple_assign': is_multiple_assign, 'isLocationBasedTaskAssignments': this.isLocationBasedTaskAssignments })
        .subscribe((success) => {
          this.assigned_to = success.data;

          if (this.assigned_to.length == 0) {
            this.noUsersAddedInTeam = true;
          }

        }, (rejected) => {

        });
    }
  }

  changeIcon($event) {
    this.showIcon = true;
    if ($event.type == 'asset') {
      this.iconVal = 'layers';
    } else if ($event.type == 'shot') {
      this.iconVal = 'movie';
    } else {
      this.iconVal = '';
      this.showIcon = false;
      this.tasks.department_id = '';
    }
  }

  close(id) {
    if (id == 3) {
      this.openModalWithComponent();
    } else {
      this.closeCreateTask.emit({ id: id });
    }
    // this.closeCreateTask.emit({ id: 3 });
  }

  getProjectsById(id) {
    // this.dataService.getDataById('getProjectById/' + id)
    //   .subscribe((success) => {
    this.project = JSON.parse(localStorage.getItem('localStorageProjectData'));
    this.isEpisodicSeries = this.project.episodic_series;
    this.isLocationBasedTaskAssignments = this.project.is_location_based_task_assignment;
    // }, (rejected) => {

    // });
  }


  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          // Here you can access the real file
          //console.log(droppedFile.relativePath, file);

          var reader = new FileReader();
          this.thumbnail_name = file.name;
          this.thumbnail_type = file.type;

          reader.onload = (event: any) => {
            this.url = event.target.result;
            if (this.thumbnail_type != 'image/png' && this.thumbnail_type != 'image/jpg' && this.thumbnail_type != 'image/jpeg' && this.thumbnail_type != 'image/gif') {
              this.thumbnail_preview_src = './assets/images/default/attechment.png';
            } else {
              this.thumbnail_preview_src = this.url;
            }
          }
          reader.readAsDataURL(file);
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        //console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public fileOver(event) {
    //console.log(event);
  }

  public fileLeave(event) {
    //console.log(event);
  }

  unlinkImage(event) {
    event.stopPropagation();
    this.thumbnail_preview_src = '';
    this.thumbnail = '';
    this.thumbnail_name = 'unset';
    this.url = '';
  }

  public calendarApplied(e: any) {
    console.log('test');
    let start_date;
    let end_date;
    if ($('#start_date').val() != '' && $('#start_date').val() != undefined) {
      start_date = moment($('#start_date').val()).format('YYYY-MM-DD HH:mm:ss');
    }
    if ($('#end_date').val() != '' && $('#end_date').val() != undefined) {
      end_date = moment($('#end_date').val()).format('YYYY-MM-DD HH:mm:ss');
    }

    // let diff = moment(end_date).diff(start_date, 'days');
    // if (diff < 0) {
    //   this.errors.end_date = "End date is negative, which is not allowed.";
    // } else if (diff < this.tasks.bid_days && diff !== 0) {
    //   this.errors.end_date = "End date is less than Bid days.";
    // } else {
    //   this.errors.end_date = "";
    // }
    // e.event
    // e.picker
  }


  public openModalWithComponent() {
    this.dataService.setProjectId(this.projectId);

    let data: any = {
      from: 'TaskComponent',
      header: 'Task'
    }

    this.dataService.setImportFrom(data);
    let list = [this.projectId];
    this.bsModalRef = this.modalService.show(ExcelmapimportComponent, {
      backdrop: 'static',
      keyboard: false
    });
    this.bsModalRef.content.title = 'Modal with component';
    this.bsModalRef.content.list = list;
    this.bsModalRef.content.event.subscribe((res: any) => {
      // this._import_opened = false;
      // this._loadImportSideWindow = false;
      // this.page = 1;
      // this.rows = [];
      // this.onChangeTable(this.config);

    });
  }


  onDocsSubmit(fattach: NgForm) {
    if (this.multipleFiles.length > 0) {
      fattach.value.attachmentArr = this.multipleFiles;
    }
  }

  //Docs
  attachmentsUpdated(files) {
    this.invalidAttach = false;
    let i;
    let multipleFiles_temp = [];
    let validFormats = [];
    for (i = 0; i < files.length; i++) {
      let file = files[i];
      let temp_file = {};
      if (file) {
        var reader = new FileReader();
        temp_file['attachment'] = file.name;
        temp_file['attachment_display_name'] = file.name;
        temp_file['attachment_type'] = file.type;
        if (file.type != 'image/jpeg' && file.type != 'image/png' && file.type != 'application/pdf' && file.type != 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && file.type != 'application/msword') {
          this.setTimer();
          //return false;
        } else {
          reader.onload = (event: any) => {
            temp_file['attachmentUrl'] = event.target.result;
            if (temp_file['attachment_type'] != 'image/png' && temp_file['attachment_type'] != 'image/jpg' && temp_file['attachment_type'] != 'image/jpeg' && temp_file['attachment_type'] != 'image/gif') {
              temp_file['attachment_preview_src'] = this.attachmentFile
            } else {
              temp_file['attachment_preview_src'] = temp_file['attachmentUrl'];
            }
            temp_file['ext'] = temp_file['attachment'].split('.').pop().toLowerCase();
          }
          reader.readAsDataURL(file);

          setTimeout(() => {
            multipleFiles_temp.push(temp_file);
          }, 0);
        }


      }
    }

    setTimeout(() => {
      if (this.isFirstTime) {
        this.multipleFiles = this.multipleFiles.concat(multipleFiles_temp);
        this.isFirstTime = false;
      } else {
        this.multipleFiles = this.TempMultipleFiles.concat(multipleFiles_temp);
      }
      multipleFiles_temp = [];
    }, 0);
  }


  unlinkAttachments(index, event) {
    event.preventDefault();
    this.multipleFiles.splice(index, 1);
  }

  download(doc) {
    window.open(this.uploadUrl + this.dirName + '/attachments/' + doc);
    //this.commonJs.uploadUrl + this.dirName + '/attachments/' + doc;
  }

  setTimer() {
    this.invalidAttach = true;
    this.timer = timer(3000); // 3000 millisecond means 3 seconds
    this.subscription = this.timer.subscribe(() => {
      this.invalidAttach = false;
    });
  }

  /** Open attechment option */
  openAttechment() {
    this.isAttechment = !this.isAttechment;
  }



  /** Bid days validation */
  bidDaysEnter(event) {
    if (this.tasks.bid_days <= 0) {
      this.tasks.bid_days = 1;
    }
  }

  // on bid days entered
  bidDaysEntered(value) {
    if (this.isDisableAssign) {
      return true;
    }
    // const startDate = new Date(this.tasks.start_date); // replace with your start date
    // const endDate = new Date(startDate);
    // endDate.setDate(startDate.getDate() + this.tasks.bid_days);
    // this.tasks.end_date = moment(endDate).format('DD MMM YYYY hh:mm A')
    const startDate = new Date(this.tasks.start_date); // replace with your start date
    this.tasks.end_date = moment($('#start_date').val(), 'DD MMM YYYY hh:mm A').add(this.tasks.bid_days, 'days').set({hour:startDate.getHours(),minute:startDate.getMinutes()}).format('DD MMM YYYY hh:mm A');
  }

  // on bid type changed
  onBidTypeChanged(){
    if (this.isDisableAssign) {
      return true;
    }
    // const startDate = new Date(this.tasks.start_date); // replace with your start date
    // const endDate = new Date(startDate);
    // endDate.setDate(startDate.getDate() + this.tasks.bid_days);
    // this.tasks.end_date = moment(endDate).format('DD MMM YYYY hh:mm A')
    if(this.selectedBidType['name'] && this.selectedBidType['name']=='Day'){
      const startDate = new Date(this.tasks.start_date); // replace with your start date

      if(this.tasks.bid_days%1==0){ // value is integer
        this.tasks.end_date = moment($('#start_date').val(), 'DD MMM YYYY hh:mm A').add(this.tasks.bid_days, 'days').set({hour:startDate.getHours(),minute:startDate.getMinutes()}).format('DD MMM YYYY hh:mm A');
      } else { // value is decimal
        this.tasks.end_date = moment($('#start_date').val(), 'DD MMM YYYY hh:mm A').add(Math.floor(this.tasks.bid_days), 'days').add(JSON.parse((this.tasks.bid_days % 1).toFixed(3))*8, 'hours').set({minute:startDate.getMinutes()}).format('DD MMM YYYY hh:mm A');
      }

    } else {
      const startDate = new Date(this.tasks.start_date); // replace with your start date

      if(this.tasks.bid_days%1==0){ // value is integer
        this.tasks.end_date = moment($('#start_date').val(), 'DD MMM YYYY hh:mm A').add(this.tasks.bid_days, 'hours').set({minute:startDate.getMinutes()}).format('DD MMM YYYY hh:mm A');
      } else { // value is decimal
        this.tasks.end_date = moment($('#start_date').val(), 'DD MMM YYYY hh:mm A').add(this.tasks.bid_days, 'hours').add((this.tasks.bid_days % 1).toFixed(2), 'minutes').format('DD MMM YYYY hh:mm A');
      }
      // console.log(this.tasks.bid_days%1==0);
    }
  }

  // To capitalize drawer heading text
  capitalizeText(headingText)
  {
      if(headingText){
        return headingText[0].toUpperCase() + headingText.slice(1) ;
      }
  }

  // To close the modal
	hideModal() {
		this.bsModalRef.hide();
	}

}
