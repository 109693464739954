import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataService } from '../../../services/data.service';
import { ToastrService } from '../../../services/toastr.service';
import { DatatableService } from '../../../services/datatable/datatable.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DateHelpModalComponent } from '../../../date-help-modal/date-help-modal.component';
declare var $: any;
import { CommonJs } from '../../../common/common-js';
import { AppConfigs } from '../../../common/app-configs';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { GridService } from 'src/app/services/grid.service';

@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

	entityName = 'Settings';
	entityNameRight = 'Preferences';
	entityNameSingular = 'Setting';
	apiRoutePrefix = 'settings';
	lists: Lists;
	focused_list: string = '';
	ListSaveButtonText: string = 'SAVE';

	bsModalRef: BsModalRef;
	data: any;
	myform: any;
	settings: { [index: string]: any } = {};
	errors: any;
	focused: string = 'focused';
	SaveButtonText: string = 'SAVE';
	settingsAll: any = JSON.parse(localStorage.getItem('settings'));
	//@Output() closeSettings = new EventEmitter();
	/*loginTypes:any;*/
	formattedDate: string;
	default_paginations = [
		{ id: "10", text: "10" },
		{ id: "20", text: "20" },
		{ id: "30", text: "30" },
		{ id: "40", text: "40" },
		{ id: "50", text: "50" }
	];
	theme_color = [
		{ id: "teal", text: "Teal" },
		{ id: "red", text: "Red" },
		{ id: "pink", text: "Pink" },
		{ id: "purple", text: "Purple" },
		{ id: "deep-purple", text: "Deep Purple" },
		{ id: "indigo", text: "Indigo" },
		{ id: "cyan", text: "Cyan" },
		{ id: "green", text: "Green" },
		{ id: "light-green", text: "Light Green" },
		{ id: "lime", text: "Lime" },
		{ id: "amber", text: "Amber" },
		{ id: "orange", text: "Orange" },
		{ id: "deep-orange", text: "Deep Orange" },
		{ id: "brown", text: "Brown" },
		{ id: "grey", text: "Grey" },
		{ id: "blue-grey", text: "Blue Grey" },
		{ id: "light-blue", text: "Light Blue" }
	];

	_list_opened: boolean = false;
	_loadListSideWindow: boolean = false;
	_ng_sidebar_data = {};
	_loadSideWindow: boolean = false;
	_opened: boolean = false;
	_permission_opened: boolean = false;
	_loadPermissionSideWindow: boolean = false;

	_import_opened: boolean = false;
	_loadImportSideWindow: boolean = false;
	_ng_import_type: string;

	public ActionButtons: any;
	public rows = this.datatableService.rows;
	public columns: Array<any> = [
		{ title: '#ID', name: 'id', className: 'hide-this' },
		{ title: 'List Name', name: 'list_name_html', width: '85%' },
		{ title: 'Items Count', name: 'items_count', className: 'item-count', width: '11%' },
	];

	busy: Subscription;
	public FilterColumns: Array<any> = ['list_name'];
	public config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: '' },
		className: ['table-striped', 'table-bordered']
	};
	public maxSize = this.datatableService.maxSize;
	public page: number = this.datatableService.page;
	public itemsPerPage: number = this.datatableService.itemsPerPage;
	public length = this.datatableService.length;

	noRecordFound: boolean = false;
	noRecordFoundMsg: any = 'Sorry, no records found!';

	changeComponent: any;
	role = (localStorage.getItem('role') != '' && localStorage.getItem('role') != undefined) ? localStorage.getItem('role') : 'superadmin';

	client_code = localStorage.getItem('client_code');
	logo_url: any;
	banner_url: any;
	banner_preview_src: string = '';
	banner_image_name: string;
	banner_image_old: string;
	banner_image_type: string;
	logo_preview_src: string = '';
	logo_image_name: string;
	logo_image_type: string;
	logo_image_old: string;
	account_banner: boolean = false;
	account_logo: boolean = false;
	uploadUrl = this.AppConfigs.getUploadUrl();

	suggestionClicked;
	saveSuggestionButtonText: string = 'SAVE';
	saveSubscriptionButtonText: string = 'SAVE';

	userId = JSON.parse(localStorage.getItem('userId'));
	user: any = JSON.parse(localStorage.getItem('user'));

	isSubscribed: boolean = false;

	isViewPermission = [];
	permissionChangedSub;
	public files: NgxFileDropEntry[] = [];


	//suggestionClicked = localStorage.getItem('suggestionClicked');
	constructor(
		private titleService: Title,
		private dataService: DataService,
		private toastrService: ToastrService,
		public commonJs: CommonJs,
		public AppConfigs: AppConfigs,
		private modalService: BsModalService,
		private datatableService: DatatableService,
		private gridService: GridService,
	) {
		this.datatableService.setParam(this.columns, 'columns');
		this.datatableService.setParam(this.config, 'config');
		this.datatableService.setParam(this.FilterColumns, 'FilterColumns');
		this.datatableService.setParam('listList', 'serverUrl');

		this.changeComponent = 'profile';
	}

	// ! NgonInit
	ngOnInit() {
		let theme = this.theme_color.findIndex((col) => {
			return col.id == this.settingsAll.theme_color;
		});

		let themeColor = this.theme_color[theme]['text'];

		this.settings = {
			title: this.settingsAll.title,
			// short_title: this.settingsAll.short_title,
			// intro_text: this.settingsAll.intro_text,
			// default_pagination: { id: this.settingsAll.default_pagination, text: this.settingsAll.default_pagination },
			// date_format_php: this.settingsAll.date_format_php,
			// no_records: this.settingsAll.no_records,
			html_prefix: this.settingsAll.html_prefix,
			date_format: this.settingsAll.date_format,
			theme_color: { id: this.settingsAll.theme_color, text: themeColor },
		};


		// const fruits = ["apple", "banana", "cantaloupe", "blueberries", "grapefruit"];
		// const index =  fruits.findIndex(fruit =>
		// 	fruit === "blueberries"
		// );


		// this.noRecordFoundMsg = (this.settingsAll.no_records != '') ? this.settingsAll.no_records : this.noRecordFoundMsg;
		this.datatableService.setParam(100, 'itemsPerPage');
		this.onChangeTable(this.config);
		this.getImages(this.client_code);
		$.AdminBSB.input.activate();
		this.lists = { list_name: '', old_list_name: '' };
		this.titleService.setTitle(this.settings.html_prefix + this.entityName);
		this.dataService.isSuggestionClicked$.subscribe(isActive => {
			this.suggestionClicked = isActive;

			if (isActive) {
				this.changeComponent = 'suggestions';
			}
		});


		if (this.role != 'superadmin') {
			this.permissionChangedSub = this.dataService.isProjectPermissionChanged$.subscribe(type => {
				setTimeout(() => {
					//console.log("type", type)
					this.isViewPermission['leave'] = this.commonJs.checkAccess('leave', 'view', { 'baiPass': ['admin'] });
				}, 500)

			});
		}
	}

	search(query, column) {
		if (column == 'theme_color') {
			this.theme_color = this.theme_color.map(v => { return v });
		} else if (column == 'default_pagination') {
			this.default_paginations = this.default_paginations.map(v => { return v });
		} else if (column == 'theme_color') {
			this.theme_color = this.theme_color.map(v => { return v });
		}
	}

	public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {
		$('#checkAll').prop('checked', false);
		this.busy = this.datatableService.onChangeTable(this.config, page)
			.subscribe((success) => {
				this.rows = success.data;
				this.rows.forEach((ele: any) => {
					ele.itemName = ele['list_name_html'].split(' ')[0];
					ele.editable = ele['list_name_html'].substring(ele['list_name_html'].indexOf(' ') + 1);

					return ele;
				});
				this.length = success.totalElements;
				this.noRecordFound = true;
			});
	}

	editList(old_list_name) {
		this.focused_list = 'focused';
		this.lists = { 'list_name': old_list_name, 'old_list_name': old_list_name };
	}

	onSubmit(form: NgForm) {
		this.SaveButtonText = 'SAVING';

		// if (form.value.default_pagination) {
		// 	form.value.default_pagination = this.settings.default_pagination.id;
		// }

		if (form.value.theme_color) {
			form.value.theme_color = this.settings.theme_color.id;
		}

		if (this.logo_url != undefined) {
			form.value.logo_image_name = this.logo_url;
		}
		if (this.banner_url != undefined) {
			form.value.banner_image_name = this.banner_url;
		}
		form.value.logo_image_old = this.settings.logo_image_old;
		form.value.banner_image_old = this.settings.banner_image_old;
		form.value.account_logo = this.account_logo;
		form.value.account_banner = this.account_banner;

		this.dataService.updateData(this.apiRoutePrefix, form.value)
			.subscribe((success) => {
				this.SaveButtonText = 'SAVED';
				setTimeout(() => {
					this.SaveButtonText = 'SAVE';
					this.account_logo = false;
					this.account_banner = false;
					this.commonJs.getAllSettings();
					let getSettings = localStorage.getItem("settings");
					if (!getSettings) {
						this.commonJs.getAllSettings();
					}
				}, 2);
				this.toastrService.notify('success', '', 'Preferences updated successfully.');

			}, (error) => {
				if (error.error) {
					var err = error.error;
					this.errors = err.error;
				}
			});
	}

	onSubmitList(formList: NgForm) {
		this.ListSaveButtonText = 'SAVING';
		if (formList.value.old_list_name != '') {
			this.dataService.updateData('list/' + formList.value.old_list_name, formList.value)
				.subscribe((success) => {
					this.ListSaveButtonText = 'SAVED';
					setTimeout(() => {
						this.ListSaveButtonText = 'SAVE';
						this.lists = { list_name: '', old_list_name: '' };
						this.focused_list = 'focuse';
						this.onChangeTable(this.config);
					}, 2);

				}, (error) => {
					if (error.error) {
						var err = error.error;
						this.errors = err.error;
					}
				});

		} else {
			this.dataService.storeData('list', formList.value)
				.subscribe((success) => {
					this.ListSaveButtonText = 'SAVED';
					setTimeout(() => {
						this.ListSaveButtonText = 'SAVE';
						this.lists = { list_name: '', old_list_name: '' };
						this.focused_list = 'focuse';
						this.onChangeTable(this.config);
					}, 2);
				}, (error) => {
					if (error.error) {
						var err = error.error;
						this.errors = err.error;
					}
				});
		}
	}

	openSideWindow(mode, id, list_name, type, editable) {
		//this._list_opened = true;
		if (list_name != undefined && list_name != '') {
			this._ng_sidebar_data['list_name'] = list_name;
		}
		this._ng_sidebar_data['id'] = id;
		this._ng_sidebar_data['mode'] = mode;
		this._ng_sidebar_data['type'] = type;
		this._ng_sidebar_data['editable'] = editable;
		this._list_opened = true;
	}

	onOpenStart() { }

	openPermissionModal() {
		this._permission_opened = true;
	}

	onOpened(loadWindow = 'list') {
		if (loadWindow == 'list') {
			this._loadListSideWindow = true;
		} else if (loadWindow == 'permission') {
			this._loadPermissionSideWindow = true;
		} else if (loadWindow == 'import') {
			this._loadImportSideWindow = true;
		}
	}

	onClosed(loadWindow = 'list') {
		if (loadWindow == 'list') {
			this._loadListSideWindow = false;
		} else if (loadWindow == 'permission') {
			this._loadPermissionSideWindow = false;
		} else if (loadWindow == 'import') {
			this._loadImportSideWindow = false;
		}
	}

	closeCreateListItemAdmins($event) {
		this.onChangeTable(this.config);
		if ($event.id == 2) {
			this._loadListSideWindow = false;
			this._list_opened = false;
		}
	}
	openModalWithComponent() {
		this.bsModalRef = this.modalService.show(DateHelpModalComponent);
		this.bsModalRef.content.mode = 'view';

		this.bsModalRef.content.onClose = () => {
			this.bsModalRef.hide();
		};

		this.commonJs.initiateJS();
	}

	changePage(page) {
		this.changeComponent = page;
		if (page == 'lists') {
			this.titleService.setTitle(this.settingsAll.html_prefix + ' Lists');
		} else if (page == 'settings') {
			this.titleService.setTitle(this.settingsAll.html_prefix + ' Appearance');
		} else if (page == 'leave-list') {
			this.titleService.setTitle(this.settingsAll.html_prefix + ' Leave List');
		}
		else if (page == 'storage-conf') {
			this.titleService.setTitle(this.settingsAll.html_prefix + ' Storage Configuration');
		}
		else if (page == 'online-access-group') {
			this.titleService.setTitle(this.settingsAll.html_prefix + ' Online Access Group');
		}
		else {
			this.titleService.setTitle(this.settingsAll.html_prefix + ' ' + page.charAt(0).toUpperCase() + page.substr(1));
		}
		// To change login logo & banner
		page != 'settings' ? this.logo_image_name = this.banner_image_name = '' : this.getImages(this.client_code);
	}

	closeSettings(pageName?) {
		// this.changeComponent = pageName;
		this.changePage('profile');
		if (pageName == 'suggestions') {
			this.settings.suggestions = '';
		}
		// else if(pageName=='appearance'){
		// 	this.changePage('settings');
		// }
	}

	Import(type) {
		this._import_opened = true;
		this._ng_import_type = type;
	}
	closeImport() {
		this._import_opened = false;
		this._loadImportSideWindow = false;
	}


	/*onChange(flag){
		if(flag) {
			this.settings.display_emp_custom_columns = 1;
		} else {
			this.settings.display_emp_custom_columns = 0;
		}
	}*/

	getImages(client_code) {
		// console.log('test')
		this.dataService.getDataById('checkLoginImages/' + client_code.toLowerCase())
			.subscribe((success) => {
				let uploadUrl = this.AppConfigs.uploadUrl;
				if (success.data.logo_url != '') {
					this.logo_image_name = success.data.logo_image;
					this.settings.logo_image_old = success.data.logo_image;
					this.logo_preview_src = uploadUrl + 'public/uploads/' + client_code.toLowerCase() + '/' + success.data.logo_url2;;
				}
				if (success.data.banner_url != '') {
					this.banner_image_name = success.data.banner_image;
					this.settings.banner_image_old = success.data.banner_image;
					this.banner_preview_src = uploadUrl + 'public/uploads/' + client_code.toLowerCase() + '/' + success.data.banner_url2;
				}
			}, (rejected) => {

			});
	}

	public UpdatedLogo(files: NgxFileDropEntry[]) {
		this.files = files;
		for (const droppedFile of files) {
			// Is it a file?
			if (droppedFile.fileEntry.isFile) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file((file: File) => {

					// Here you can access the real file
					//console.log(droppedFile.relativePath, file);

					var reader = new FileReader();
					this.logo_image_name = file.name;
					this.logo_image_type = file.type;
					this.account_logo = true;
					reader.onload = (event: any) => {
						this.logo_url = event.target.result;
						if (this.logo_image_type != 'image/png' && this.logo_image_type != 'image/jpg' && this.logo_image_type != 'image/jpeg' && this.logo_image_type != 'image/gif') {
							this.logo_preview_src = './assets/images/default/attechment.png';
						} else {
							this.logo_preview_src = this.logo_url;
						}
					}
					reader.readAsDataURL(file);
				});
			} else {
				// It was a directory (empty directories are added, otherwise only files)
				const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
				//console.log(droppedFile.relativePath, fileEntry);
			}
		}
	}

	public fileOver(event) {
		//console.log(event);
	}

	public fileLeave(event) {
		//console.log(event);
	}

	public UpdatedBanner(files: NgxFileDropEntry[]) {
		this.files = files;
		for (const droppedFile of files) {
			// Is it a file?
			if (droppedFile.fileEntry.isFile) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file((file: File) => {

					// Here you can access the real file
					//console.log(droppedFile.relativePath, file);

					var reader = new FileReader();
					this.banner_image_name = file.name;
					this.banner_image_type = file.type;
					this.account_banner = true;
					reader.onload = (event: any) => {
						this.banner_url = event.target.result;
						if (this.banner_image_type != 'image/png' && this.banner_image_type != 'image/jpg' && this.banner_image_type != 'image/jpeg' && this.banner_image_type != 'image/gif') {
							this.banner_preview_src = './assets/images/default/attechment.png';
						} else {
							this.banner_preview_src = this.banner_url;
						}
					}
					reader.readAsDataURL(file);
				});
			} else {
				// It was a directory (empty directories are added, otherwise only files)
				const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
				//console.log(droppedFile.relativePath, fileEntry);
			}
		}
	}

	unlinkImage(event, image) {
		event.stopPropagation();
		if (image == 'logo') {
			this.logo_preview_src = '';
			this.logo_image_name = '';
			this.logo_url = '';
			this.account_logo = true;
		}
		if (image == 'banner') {
			this.banner_preview_src = '';
			this.banner_image_name = '';
			this.banner_url = '';
			this.account_banner = true;
		}
	}

	onSubmitSuggestions(form: NgForm) {
		this.dataService.storeData('suggestionsStore', form.value)
			.subscribe((success) => {
				this.saveSuggestionButtonText = 'SAVED';
				setTimeout(() => {
					this.saveSuggestionButtonText = 'SAVE';
				}, 2);
				this.settings.suggestions = "";
				//this.toastrService.notify('success','','Preferences updated successfully.');
				this.toastrService.notify(success.status, '', success.message);

			}, (error) => {
				if (error.error) {
					var err = error.error;
					this.errors = err.error;
				}
			});
	}


	onOpenedEmp() {
		this._loadSideWindow = true;
	}

	onClosedEmp() {
		this._loadSideWindow = false;
		this.gridService.updateValue('New value from parent');
	}
	closeCreateUser() {
	}

	handleOpenSideWindowClick() {
		this._opened = true;
	}


}

// interface Settings {
//   title:string,
//   short_title:string,
//   intro_text:string,
//   html_prefix:string,
//   default_pagination:string,
//   no_records:string,
//   date_format:string,
//   date_format_php:string,
//   theme_color:string

// }
interface Lists {
	list_name: string,
	old_list_name: string
}
