import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { CommonJs } from 'src/app/common/common-js';
import { DataService } from 'src/app/services/data.service';
import { GridService } from 'src/app/services/grid.service';
import { ToastrService } from 'src/app/services/toastr.service';

@Component({
  selector: 'app-assign-team-member',
  templateUrl: './assign-team-member.component.html',
  styleUrls: ['./assign-team-member.component.css']
})
export class AssignTeamMemberComponent implements OnInit {

  columns = [];
  projectId = '';

  public config: any = {
		FilterByColumns: {
			"emp_code_final": "",
			"vendor_id": "",
			"user_name": "",
			"role_name": "",
			"primary_department": "",
			"secondary": "",
			"vertical": ""
		},
		itemsPerPage: 50,
		start: 0,
		"sortNew": {},
		"FilterColumns": ["user_name"],
		"filtering": {
			"filterString": ""
		},
		sort: {},
		where: { project_id: this.projectId },
	};

  @Input('teamDetail') teamDetail:any = {};
  roles:any[] = [];
  roleTypeList: any[] = [];
  filteredRoleTypeList: any[] = [];

  totalElements:any = 0;


  selectedRole:string = '';
  empNameSearch:string = '';
  pagePayload: any = { page: 0, itemsPerPage: 50 };
  pageSize: number = 50;
  public page: number = 1;
  selectedIds: any[] = [];
  role_Id:number = 0;
  role_TypeId:number = 0;
  highLightRoleName:string = '';

  errors: any;

  @Output() closeAssignTeam = new EventEmitter();

  isLoading:boolean = true;
  isSubmitLoading:boolean = false;

  constructor(
    private gridService: GridService,
    private dataService: DataService,
    public commonJs: CommonJs,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
			this.projectId = params["id"];
		});

    // console.log(this.teamDetail);
    this.dataService.getOtherData('getRolesByType', { 'query': "", 'role_type_id': this.teamDetail.id })
    .subscribe((success) => {
      this.roles = success.data;
      // console.log(this.roles);
      if(this.roles.length>0){
        this.getUsersByRole(this.roles[0].id, this.teamDetail.id, this.roles[0].name, 'showAll');
      }
    },(error)=>{
      this.roles = [];
      console.log(this.roles);
    });
  }

  // For selecting role type
  search(query, column, roletype = "", pts = []) {

      this.dataService.getOtherData('getRolesByType', { 'query': query['query'], 'role_type_id': roletype })
        .subscribe((success) => {
          this.roles = success.data;
        },(error)=>{
          this.roles = [];
          console.log(this.roles);
        });

  }

  selectedOptionChange(event, parentId) {
    // this.selectedRoleType[parentId] = event.id;
    // this.roleName = event.name;
    // console.log(event, this.selectedRole);
    this.empNameSearch = '';
    this.getUsersByRole(event.id, this.teamDetail.id, event.name);
    // if (parentId != '') {
    //   this.isButtonDisabled[parentId] = parentId;
    // } else {
    //   this.isButtonDisabled = [];
    // }
  }

  // Filter emp name
  filterEmpName(){

    //  console.log(this.empNameSearch);

    if(this.empNameSearch!=''){
      // console.log(this.empNameSearch, this.roleTypeList.filter((ele:any)=>(ele.user_name.toLowerCase()==this.empNameSearch.toLowerCase())));
      this.filteredRoleTypeList = this.roleTypeList.filter((ele:any)=>
        ( ele.user_name.toLowerCase().includes(this.empNameSearch.toLowerCase()) || ( ele.emp_code_final && ele.emp_code_final.toLowerCase().includes(this.empNameSearch.toLowerCase() )) || ( ele.primary_department && ele.primary_department.toLowerCase().includes(this.empNameSearch.toLowerCase()) ) ));
    }
    else{
      this.filteredRoleTypeList = this.roleTypeList;
    }
  }

  // For getting users by role
  getUsersByRole(role, roletype, roleName, showAll?) {

    this.role_Id = role;
    this.role_TypeId = roletype;

    !showAll ? (this.highLightRoleName = roleName) : (this.highLightRoleName ='') ;

      this.config.where ={
        role: role,
        roletype: roletype,
        project_id: this.projectId,
      }

		this.dataService.getListData("getUsersByRole", this.config).subscribe(
				(success) => {

          this.totalElements =  success.totalElements;
          let tempRoleTypeList = success.data;
          // console.log(tempRoleTypeList);

          if(showAll){

            this.roleTypeList = this.filteredRoleTypeList = tempRoleTypeList.map((ele)=>{ ele.isChecked = false;return ele; });
          }else{
            // this.roleTypeList = this.filteredRoleTypeList = tempRoleTypeList.filter((ele)=>(ele.role_name==roleName)).map((ele)=>{ ele.isChecked = false;return ele; });
            this.roleTypeList = this.filteredRoleTypeList = tempRoleTypeList.map((ele)=>{ ele.isChecked = false;return ele; });
          }
          this.selectedIds = [];
          // console.log(this.roleTypeList);
          this.isLoading = false;
				},
				(rejected) => {
          console.log(rejected);
          this.roleTypeList = this.filteredRoleTypeList = [];
          this.isLoading = false;
				}
			);
	}

  // For getting first two letters of name
  getInitials(inputString: string): string {
    const words = inputString.trim().split(' ');
    if (words.length >= 2) {
      const firstInitial = words[0].charAt(0).toUpperCase();
      const secondInitial = words[1].charAt(0).toUpperCase();
      return `${firstInitial}${secondInitial}`;
    } else if (words.length === 1) {
      const firstInitial = words[0].charAt(0).toUpperCase();
      return `${firstInitial}`;
    } else {
      return '';
    }
  }

  // On checkbox change
  checkboxChanged(event, data){
    // this.selectedIds = this.filteredRoleTypeList.filter((ele)=>(ele.isChecked)).map((ele) => ele.id);
    this.selectedIds = this.filteredRoleTypeList.filter((ele)=>(ele.isChecked)).map((ele) => { return {'id':ele.id, 'roleId': ele.role_id, 'roleName': ele.role_name } });
    // console.log(this.selectedIds);
  }

  // Save assigned team
  saveTeam() {
    // this.isSubmitted = true;
    this.isSubmitLoading = true;
    if(this.selectedRole){
      // console.log(this.selectedRole);
      this.selectedIds.map((ele)=>{ ele['roleId'] = this.selectedRole['id'], ele['roleName'] = this.selectedRole['name'] });
    }
    // console.log(this.selectedIds);return;

    this.dataService
			.storeData("saveProjectTeam", {
				project_id: this.projectId,
				user_id: this.selectedIds,
				role_id: this.role_Id,
				role_type_id: this.role_TypeId,
				action: "attach",
			})
			.subscribe(
				(success) => {
          this.closeDrawer();
          this.isSubmitLoading = false;
				},
				(error) => {
					if (error.error) {
						var err = error.error;
						this.errors = err.error;
						this.toastrService.notify(
							"warn",
							"",
							"Please correct all highlighted errors and try again."
						);
					}
          this.isSubmitLoading = false;
				}
			);
      // this.deleteAllId = this.selected = [];
	}

  // On cancel
  closeDrawer(){
    this.closeAssignTeam.emit(true);
  }

}
