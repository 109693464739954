import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { DaterangePickerComponent } from 'ng2-daterangepicker';
import { CommonJs } from '../../../../common/common-js';
import { DataService } from '../../../../services/data.service';
import { ToastrService } from '../../../../services/toastr.service';
import swal from 'sweetalert2';
import { NotificationsService } from '../../../../services/notifications/notifications.service';
declare var $: any;
import * as moment from "moment";
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-leave-add',
  templateUrl: './leave-add.component.html',
  styleUrls: ['./leave-add.component.css']
})
export class LeaveAddComponent implements OnInit {

  leaveForm: FormGroup;
  f1Focused: string = '';
  SaveButtonText: string = 'APPLY';
  leaveType: Array<any>;
  DateFormat: any;
  fromDate: any;
  toDate: any;
  settingsAll: any = JSON.parse(localStorage.getItem('settings'));
  userData = JSON.parse(localStorage.getItem('user'));
  leaveDays: Array<any>;
  leaveDaysBoolean: boolean = false;
  private picker: DaterangePickerComponent;
  allLeaves: any;
  fromAndTodate: any = {};
  noRecordFoundMsg: any = 'Sorry, no records found!';
  settings: any = JSON.parse(localStorage.getItem('settings'));
  editAction: boolean = false;
  role = (localStorage.getItem('role') != '' && localStorage.getItem('role') != undefined) ? localStorage.getItem('role') : 'superadmin';
  isViewPermission = [];
  permissionChangedSub;
  validationError: any;
  leaveSubs: any;
  clientCode = localStorage.getItem('access_code');
  supervisorList = [];
  userId = JSON.parse(localStorage.getItem('userId'));
  apiRoutePrefix = 'users';
  userPrimaryDept = {};
  superVisorId: any = null;
  producer: boolean = false;

  public to = {
    singleDatePicker: true,
    autoUpdateInput: true,
    showDropdowns: true,
    autoApply: true,
    "maxDate": "31 Dec 2030",
    locale: {
      format: 'MMM DD, YYYY'
    }
  }

  public from = {
    singleDatePicker: true,
    autoUpdateInput: true,
    showDropdowns: true,
    autoApply: true,
    "maxDate": "31 Dec 2030",
    locale: {
      format: 'MMM DD, YYYY'
    }
  }

  constructor(
    private fb: FormBuilder,
    public dataService: DataService,
    private toastrService: ToastrService,
    public commonjs: CommonJs,
    public datepipe: DatePipe,
    private notificationsService: NotificationsService,
    public cd: ChangeDetectorRef
  ) {
    localStorage.getItem('role') != 'admin' ? this.getPrimaryDept() : '';
    if (this.role == "production" || this.role == "production_manager" || this.role == "producer") {
      this.producer = true;
    }
    if (this.role != 'superadmin') {
      this.permissionChangedSub = this.dataService.isProjectPermissionChanged$.subscribe(type => {
        setTimeout(() => {
          this.isViewPermission['add'] = this.commonjs.checkAccess('leave', 'add', { 'baiPass': ['admin'] });
          this.isViewPermission['edit'] = this.commonjs.checkAccess('leave', 'edit', { 'baiPass': ['admin'] });
          this.isViewPermission['delete'] = this.commonjs.checkAccess('leave', 'delete', { 'baiPass': ['admin'] });
          //console.log(this.isViewPermission)
        }, 500)
      });
    }

    this.fromAndTodate.from = this.datepipe.transform(new Date(), this.DateFormat);
    this.fromAndTodate.to = this.datepipe.transform(new Date(), this.DateFormat);

    this.fromAndTodate.from2 = this.datepipe.transform(new Date(), this.DateFormat);
    this.fromAndTodate.to2 = this.datepipe.transform(new Date(), this.DateFormat);
  }

  ngOnInit() {
    this.getLeaves();
    // this.noRecordFoundMsg = (this.settings.no_records != '') ? this.settings.no_records : this.noRecordFoundMsg;
    this.DateFormat = this.settingsAll.date_format;
    // this.from.locale.format = this.DateFormat;
    this.leaveForm = this.fb.group({
      id: [null],
      user_id: [null],
      leavetype: [null],
      // supervisor: [this.superVisorId],
      from: [null],
      to: [null],
      leaveDays: [1],
      reason: [null],
    });

    this.getReportingList();

    this.leaveType = [
      {
        name: 'Casual Leave',
        id: 1
      },
      {
        name: 'Leave Without Pay',
        id: 2
      },
      {
        name: 'Sick Leave',
        id: 3
      }
    ];

    this.leaveDays = [
      {
        name: 'Full Day',
        id: 1
      },
      {
        name: '1st Half',
        id: 2
      },
      {
        name: '2nd Half',
        id: 3
      },
    ];


    if (environment.prodType != 'offline') {
      //this.dataService.getNotifications(this.clientCode,this.userId,'header');
      this.leaveSubs = this.dataService.isNotification$.subscribe((notification) => {
        // console.log(notification.type);
        this.getLeaves();
      });
    }

    // this.leaveType =
  }


  /** Select datepicker */
  selectedDatepicker($event, type) {
    if (type == 'from') {
      let fromDate: any = moment(new Date(this.fromAndTodate.to)).isSameOrBefore($event.start);
      if (fromDate) {
        this.fromAndTodate.to = this.datepipe.transform($event.start, 'MMM d, y');
        this.fromAndTodate.to2 = this.datepipe.transform($event.start, 'yyyy-MM-dd');
      }
      this.fromAndTodate.from = this.datepipe.transform($event.start, 'MMM d, y');
      this.fromAndTodate.from2 = this.datepipe.transform($event.start, 'yyyy-MM-dd');
      this.cd.markForCheck();
    } else {
      let toDate: any = moment(new Date(this.fromAndTodate.from)).isSameOrBefore($event.start);
      if (!toDate) {
        this.fromAndTodate.from = this.datepipe.transform($event.start, 'MMM d, y');
        this.fromAndTodate.from2 = this.datepipe.transform($event.start, 'yyyy-MM-dd');
      }
      this.fromAndTodate.to = this.datepipe.transform($event.start, 'MMM d, y');
      this.fromAndTodate.to2 = this.datepipe.transform($event.start, 'yyyy-MM-dd');
      this.cd.markForCheck();
    }
  }

  /** On submit*/
  onSubmit(form: any) {
    //console.log(form)
    //console.log(this.fromAndTodate)
    if (!this.checkControlPost(form)) {
      if (this.findInvalidControls().length === 0) {
        const data: any = {
          "id": this.leaveForm.get('id').value,
          "type": form.leavetype.id,
          "supervisor": this.superVisorId,
          "from": this.datepipe.transform(this.fromAndTodate.from2, 'yyyy-MM-dd'),
          "to": this.datepipe.transform(this.fromAndTodate.to2, 'yyyy-MM-dd'),
          "leave_day": form.leaveDays ? form.leaveDays['id'] : 1,
          "reason": form.reason
        }

        if (this.editAction) {
          data.user_id = this.leaveForm.get('user_id').value;
        }

        this.SaveButtonText = 'Applying';


        if (this.editAction) {

          this.dataService.editLeave(data).subscribe(
            res => {
              if (res.status == 'success') {
                this.toastrService.notify('success', '', res.message);
                this.SaveButtonText = 'APPLY';
                if (res.data.notifications) {
                  let that = this;
                  that.dataService.addNotification(that.clientCode, res.data.notifications['user_id'], res.data.notifications);
                }
                this.notificationsService.sendPushMessage(res.data.notifications.description, 'Leave', res.data.notifications.type);
                this.resetForm();
                this.getLeaves();
              } else {
                this.toastrService.notify('warn', '', res.message);
                this.SaveButtonText = 'APPLY';
              }
              this.editAction = false
              this.validationError = '';
            }, error => {
              this.SaveButtonText = 'APPLY';
              var err = error.error;
              //console.log(err);
              this.toastrService.notify('warn', '', err.message);
              this.validationError = err;
              //console.log("this.validationError", this.validationError)
              //console.log(error)
            },
            () => {

            }
          )

        } else {
          this.dataService.addLeave(data).subscribe(
            res => {
              if (res.status == 'success') {
                this.toastrService.notify('success', '', res.message);
                this.SaveButtonText = 'APPLY';
                if (res.data.notifications) {
                  let that = this;
                  that.dataService.addNotification(that.clientCode, res.data.notifications['user_id'], res.data.notifications);
                }
                this.notificationsService.sendPushMessage(res.data.notifications.description, 'Leave', res.data.notifications.type);
                this.resetForm();
                this.getLeaves();
              } else {
                this.toastrService.notify('warn', '', res.message);
                this.SaveButtonText = 'APPLY';
              }
              this.validationError = '';
            }, error => {
              this.SaveButtonText = 'APPLY';
              var err = error.error;
              //console.log(err);
              this.toastrService.notify('warn', '', err.message);
              this.validationError = err
              //console.log("this.validationError", this.validationError)
              //console.log(error)
            },
            () => {

            }
          )
        }
      }
    }

    // if (this.checkControlPost(form)) {
    //   this.markFormTouched(this.leaveForm);
    // }
  }

  /** Get leaves */
  getLeaves() {
    this.dataService.getLeave('leaveList').subscribe((res: any) => {
      this.allLeaves = res.data;
      this.allLeaves.map((leave) => { leave.typeShortName = leave.type.match(/\b\w/g).join(''); });
    });
  }

  /** Delete my leave */
  deleteMyLeave(id: any) {
    let that = this;
    swal.fire({
      title: 'Delete',
      text: 'Are you sure you want to delete these leave?',
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value) {

        that.dataService.deleteLeave(id).subscribe(
          result => {
            //console.log(result);
            if (result.status == 'success') {
              that.toastrService.notify('success', '', result.message);
              that.getLeaves();
            }
          },
          errror => {
            that.toastrService.notify('warn', '', errror.message);
          },
          () => {

          }
        )

      } else {
        //console.log('cancelled');
      }
    }
    );
  }

  /** Edit Leave */
  editLeave(data: any) {
    //console.log(data);
    this.editAction = true;
    this.leaveDaysBoolean = true;
    this.fromAndTodate.from = this.datepipe.transform(new Date(data.from), 'MMM dd, y');
    this.fromAndTodate.to = this.datepipe.transform(new Date(data.to), 'MMM dd, y');
    this.from['startDate'] = moment(data.from).format(this.from.locale.format);
    this.to['startDate'] = moment(data.to).format(this.to.locale.format);
    var leaveTypeData: any;
    var leaveDaysData: any;

    this.leaveType.filter((type: any) => {
      if (type.name == data.type) {
        leaveTypeData = type;
      }
    });

    this.leaveDays.filter((days: any) => {
      if (days.name == data.leave_day) {
        leaveDaysData = days;
      }
    });

    this.superVisorId = this.supervisorList.filter((sv) => (sv.primary_department == parseInt(data.reportings_id)))[0];
    this.leaveForm.patchValue({
      id: data.id,
      user_id: data.user_id,
      leavetype: leaveTypeData,
      leaveDays: 1,
      reason: data.reason
    });
  }


  /** Disconnect s3 bucket */
  resetForm() {
    this.editAction = false;
    this.leaveDaysBoolean = false;
    this.fromAndTodate.from = this.datepipe.transform(new Date(), 'MMM d, y');
    this.fromAndTodate.to = this.datepipe.transform(new Date(), 'MMM d, y');
    this.validationError = '';
    this.superVisorId = this.supervisorList.filter((sv) => (sv.primary_department == JSON.stringify(this.userPrimaryDept['id'])))[0];
    this.leaveForm.get('leavetype').clearValidators();
    this.leaveForm.get('leavetype').updateValueAndValidity();
    this.leaveForm.get('reason').clearValidators();
    this.leaveForm.get('reason').updateValueAndValidity();
    this.leaveForm.reset();
  }


  /** Check Control Post */
  checkControlPost = (post) => {
    let invalid = false;
    Object.keys(post).forEach((key: string) => {

      if (key === 'leavetype' && !this.leaveForm.get(`${key}`).value) {
        this.leaveForm.get(`${key}`).setValidators([Validators.required]);
        this.leaveForm.get(`${key}`).updateValueAndValidity({ onlySelf: true, emitEvent: false });
        return invalid = true;
      }

      if (this.leaveDaysBoolean) {
        if (key === 'leaveDays' && !this.leaveForm.get(`${key}`).value) {
          this.leaveForm.get(`${key}`).setValidators([Validators.required]);
          this.leaveForm.get(`${key}`).updateValueAndValidity({ onlySelf: true, emitEvent: false });
          return invalid = true;
        }
      }

      if (key === 'reason' && !this.leaveForm.get(`${key}`).value) {
        this.leaveForm.get(`${key}`).setValidators([Validators.required]);
        this.leaveForm.get(`${key}`).updateValueAndValidity({ onlySelf: true, emitEvent: false });
        return invalid = true;
      }
    });
    return invalid;
  }

  /** Find Invalid Controls */
  findInvalidControls = () => {
    const invalid = [];
    const controls = this.leaveForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  /**Mark Form Touched*/
  markFormTouched = (group: FormGroup | FormArray) => {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }


  /** Get data  */
  getFilterData() {
    this.getReportingList();
    this.leaveType = [
      {
        name: 'Casual Leave',
        id: 1
      },
      {
        name: 'Leave Without Pay',
        id: 2
      },
      {
        name: 'Sick Leave',
        id: 3
      }
    ];
  }

  getFilteredReportingList() {

  }

  /** On select leave type */
  onSelectLeaveType($event) {
    this.leaveDaysBoolean = true;
    //console.log($event)
  }

  /** Leave days */
  getLeaveDays($event) {
    this.leaveDays = [
      {
        name: 'Full Day',
        id: 1
      },
      {
        name: '1st Half',
        id: 2
      },
      {
        name: '2nd Half',
        id: 3
      },
    ];
  }

  /** On select leave days */
  onSelectLeaveDays($event) {
  }

  /** On select supervisor */
  onSelectSupervisor(event) {
    this.superVisorId = event;
    //console.log(this.superVisorId , event);
  }

  // To get reporting list
  getReportingList() {
    this.dataService.getReportingList().subscribe((success) => {
      this.supervisorList = success.data;
      this.superVisorId = this.supervisorList.filter((sv) => (sv.primary_department == JSON.stringify(this.userPrimaryDept['id'])))[0];
    }, (rejected) => {
      //console.log(rejected);
    });
  }

  // To get primary dept of user
  getPrimaryDept() {
    this.dataService.getDataById(this.apiRoutePrefix + '/' + this.userId + '/getProfile')
      .subscribe((success) => {
        // console.log(success);
        this.userPrimaryDept = success.data.primary_department;
      }, (error) => {

      });
  }

  // Tab changed to leave history
  goToLeaveHistory() {
    this.dataService.getLeave('leaveList').subscribe((resp) => {
      //console.log("leave", resp)
    });
  }

}
