import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { Subscription, interval } from 'rxjs';
import Pusher from 'pusher-js/worker';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CommonJs } from 'src/app/common/common-js';
import { DatatableService } from 'src/app/services/datatable/datatable.service';
import { Title } from '@angular/platform-browser';
import { AppConfigs } from 'src/app/common/app-configs';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { ToastrService } from 'src/app/services/toastr.service';

import { environment } from '../../../../environments/environment';
import { columns } from './review-columns';
import { PusherService } from 'src/app/services/pusher.service';
declare var $: any;

@Component({
  selector: 'app-tasks-review-datatable',
  templateUrl: './tasks-review-datatable.component.html',
  styleUrls: ['./tasks-review-datatable.component.css']
})
export class TasksReviewDatatableComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('idsT', { static: false }) public idsT: TemplateRef<any>;
  @ViewChild('imageIcon', { static: false }) public imageIcon: TemplateRef<any>;
  @ViewChild('taskThumbnail', { static: false }) public taskThumbnail: TemplateRef<any>;
  @ViewChild('info', { static: false }) public info: TemplateRef<any>;
  @ViewChild('version', { static: false }) public version: TemplateRef<any>;
  @ViewChild('status', { static: false }) public status: TemplateRef<any>;
  @ViewChild('receivedDate', { static: false }) public receivedDate: TemplateRef<any>;
  @ViewChild('assigned', { static: false }) public assigned: TemplateRef<any>;

  activeTab: string = 'ready';

  entityName = ' Task Review';
  entityNameSingular = ' Task Review';
  projectId = '';

  reviewTaskListActive = [];
  reviewTaskListPipeline = [];
  bucketTaskList = [];
  reviewTaskListUnderReview = [];
  reviewTaskListRejectedInv = [];
  reviewTaskListCompleted = [];
  bucketSelectedTasksId = [];

  userNotiCountActive = '';
  userNotiCountPipeline = '';
  userNotiCountUnderReview = '';
  userNotiCountCompleted = '';

  _task_comment_opened: boolean = false;
  _loadCommentSideWindow: boolean = false;
  hideDownloads: boolean = false;
  pipelineDownloads: boolean = false;
  readyDownloads: boolean = false;
  completedDownloads: boolean = false;
  _ng_sidebar_comment_data = {};

  _task_checklist_opened: boolean = false;
  _loadChecklistSideWindow: boolean = false;
  _ng_sidebar_checklist_data = {};

  projectroles = {};
  currentRole = '';

  projectroletype = JSON.parse(localStorage.getItem('projectRoleType'));
  is_external_login: boolean = false;
  is_external_review: boolean = false;

  is_open_detail = false;
  selectedTask: { [index: string]: any } = {};
  reRender: { [index: string]: any } = {};

  private timer;
  settings: any = JSON.parse(localStorage.getItem('settings'));

  //Filters
  taskReview: { [index: string]: any } = {};
  where: { [index: string]: any } = {};
  episodes: { [index: string]: any } = {};
  sequences: { [index: string]: any } = {};
  assets: { [index: string]: any } = {};
  shots: { [index: string]: any } = {};
  departments: { [index: string]: any } = {};
  artists: { [index: string]: any } = {};
  manualTasks: { [index: string]: any } = {};
  filterIds = [];
  filterArr = [];

  //statusArr=this.appConfigs.sht_ast_status;
  statusArr = [];

  //Import
  _ng_import_type: string;
  _loadImportSideWindow: boolean = false;
  _import_opened: boolean = false;
  tab = 'ready';

  clientCode = localStorage.getItem('access_code');
  userId = localStorage.getItem('userId');
  subscribeisUserTasksList;
  studioType: any;

  //primary and secondary default filter
  primarySecondaryArr = [
    { "id": 'All', "name": "All", 'checked': false },
    { "id": 'primary', "name": "Primary", 'checked': true },
    { "id": 'secondary', "name": "Secondary", 'checked': true }
  ];

  primarySecondaryIds = [];
  primaryAndSecondaryArr = [];

  //save filter
  isLoading: boolean = true;
  isClearFilter: boolean = false;

  //Pagination
  busy: Subscription;
  public rows = this.datatableService.rows;
  public FilterColumns: Array<any> = ['list_name'];
  public config: any = {
    paging: true,
    sorting: { columns: [] },
    filtering: { filterString: '' },
    className: ['table-striped', 'table-bordered'],
    where: { 'project_id': this.projectId, tab: 'ready', role: '', is_external_login: this.is_external_login, filterArr: this.filterArr, primaryAndSecondaryArr: this.primaryAndSecondaryArr },
    isLoading: this.isLoading,
    isClearFilter: this.isClearFilter,
  };
  public maxSize = this.datatableService.maxSize;
  public page: number = this.datatableService.page;
  public itemsPerPage: number = this.datatableService.itemsPerPage;
  public readyLength = this.datatableService.length;
  public length = this.datatableService.length;
  public numPages: number = this.datatableService.numPages;

  noRecordFound: boolean = false;
  noRecordFoundMsg: any = 'Sorry, no records found!';
  totalElements: number;
  isEpisodic: boolean;
  isFilterApplied: boolean;
  _loadSideWindow: boolean = false;

  checkboxColumn = {
    prop: 'selected',
    name: '',
    sortable: false,
    frozenLeft: true,
    draggable: true,
    resizable: true,
    headerCheckboxable: true,
    checkboxable: true,
    width: 45,
  }


  versionsArr = [
    { "id": 1, "name": "V001" },
    { "id": 2, "name": "V002" },
    { "id": 3, "name": "V003" },
    { "id": 4, "name": "V004" },
    { "id": 5, "name": "V005 and Greater" }
  ];

  urlParam = [];

  isReviewSelection: boolean = false;

  isFilterSubmitted: boolean = false;
  isFilterReset: boolean = false;

  uploadUrl = this.commonJs.getUploadUrl();

  isTaskReviewedSubscribed: Subscription;

  datatableRows: any[] = [];
  dataTableColumns: TableColumn[] = [];
  pageSize: number = 100;
  selectedBucket = [];
  totalItems: number = 0;
  currentPage: number = 0;
  openedPage: number = 1;
  pagePayload: any = { page: 0, itemsPerPage: 30 };

  isAdvanceSearchEnabled: boolean = false;
  isAdvanceSearchSubmitted: boolean = false;

  _ng_sidebar_data = {};
  _opened: boolean = false;


  constructor(
    private dataService: DataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public commonJs: CommonJs,
    private datatableService: DatatableService,
    public appConfigs: AppConfigs,
    private titleService: Title,
    private notificationsService: NotificationsService,
    private toastrService: ToastrService,
    private pusherService: PusherService
  ) {
    this.datatableService.setParam([], 'columns');
    this.datatableService.setParam(this.config, 'config');
    this.datatableService.setParam(this.FilterColumns, 'FilterColumns');
    this.datatableService.setParam('getReviewTaskList', 'serverUrl');
  }

  ngOnInit() {

    this.setDatatableColumns();

    this.urlParam = this.router.url.split("/");
    this.titleService.setTitle(this.settings.html_prefix + this.entityName);
    this.projectroles = (function () {
      var result;
      try {
        let result = JSON.parse(localStorage.getItem('projectRoles'))
        return result;
      } catch (exception) {
      }
    }());

    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectId = params['id'];
      this.config.where.project_id = this.projectId;
    });

    if (this.projectroletype != undefined && this.projectroletype['project_' + this.projectId] != undefined && this.projectroletype['project_' + this.projectId] == 'external') {
      this.is_external_login = true;
    }
    //this.getReviewTaskList(this.tab);
    if (this.projectroles) {
      this.currentRole = this.projectroles['project_' + this.projectId];
      this.config.where.role = this.currentRole;
    }

    this.dataService.getDataById('getProjectSettings/' + this.projectId)
      .subscribe((success) => {
        this.is_external_review = success.data.is_external_review;
        if (success.data.is_review_department_selection != undefined) {
          this.isReviewSelection = success.data.is_review_department_selection;
        }

        if (this.isReviewSelection || this.currentRole == 'lead') {
          this.primarySecondaryArr = [
            { "id": 'All', "name": "All", 'checked': false },
            { "id": 'primary', "name": "Primary", 'checked': false },
            { "id": 'secondary', "name": "Secondary", 'checked': false }
          ];

          this.primarySecondaryIds = [];
          this.primaryAndSecondaryArr = [{ 'entity': 'user-depts', 'primarySecondaryIds': [] }];
          this.config.where.primaryAndSecondaryArr = [{ 'entity': 'user-depts', 'primarySecondaryIds': [] }];
          this.onChangeTable(this.config);
        } else {
          this.primarySecondaryIds = ["primary", "secondary"];
          this.primaryAndSecondaryArr = [{ 'entity': 'user-depts', 'primarySecondaryIds': ['primary', 'secondary'] }];
          this.config.where.primaryAndSecondaryArr = [{ 'entity': 'user-depts', 'primarySecondaryIds': ['primary', 'secondary'] }];
          this.onChangeTable(this.config);
        }

        this.getReviewerTasks();

      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.toastrService.notify('warn', '', err.message);
          this.router.navigate(['/dashboard']);
        }
      });


    //this.config.where.filterArr = [];
    //this.onChangeTable(this.config);

    //this.getTabNotiCount();

    this.studioType = (function () {
      var result;
      try {
        let result = localStorage.getItem('studio_type');
        return result;
      } catch (exception) {
      }
    }());

    if (environment.prodType == 'offline') {
      this.timer = interval(10000).subscribe(x => {
        this.getReviewTaskList(this.tab, 'offline');
        //this.getTabNotiCount();
      });
    }
    //this.dataService.getNotifications(this.clientCode,this.userId);
    if (environment.prodType != 'offline') {
      this.subscribeisUserTasksList = this.dataService.isUserTasksList$.subscribe(notification => {
        let notifyTo = notification['notification_to'];
        if (notifyTo == 'reviewer' || notifyTo == '0' || notifyTo == 'task_transfer' || notifyTo == 'task_on_hold' || notifyTo == 'parent_dept_task_approved') {
          if (this.currentRole != 'lead') {
            this.primaryAndSecondaryArr = [{ 'entity': 'user-depts', 'primarySecondaryIds': ['primary', 'secondary'] }];
          }
          this.tab = localStorage.getItem('reviewOpenedTab');
          this.getReviewTaskList(this.tab, 'notification');
          if (notification['notification_id'] && notification['notification_id'] != '' && this.urlParam[4] != 'tasks-review-grid' && this.urlParam[4] != 'tasks-track') {
            this.sendPushNotification(this.userId, notification['notification_id']);
          }
        }
      });
    }

    this.isTaskReviewedSubscribed = this.dataService.isTaskReviewed$.subscribe(isStatusChanged => {
      this.selectTab(this.tab);
      this.is_open_detail = false;
    });

    setTimeout(() => {
      $('#check-user-depts-ready-primary').prop('checked', true);
      $('#check-user-depts-ready-secondary').prop('checked', true);
    }, 200);

    this.getReviewFilters(this.tab);

    //get Custom task List
    this.getStatusList();
    //this._eventsService.on('reloadTaskList', this.callbackListener);

    /** Pusher js live update data without reload page */
    if (environment.production) {
      // const channel = pusher.subscribe(`${'tasks.'}${this.projectId}`);
      const channel = this.pusherService.subscribeToChannel(`tasks`);
      channel.bind(`tasks`, (data) => {
        if(data.projectId == this.projectId){
          this.onChangeTable(this.config);
        }
      });
    }
  }

  // to show advanved search
  showAdvanceSearch() {
    this.isAdvanceSearchEnabled = !this.isAdvanceSearchEnabled;
  }

  // for setting datatable columns
  setDatatableColumns() {

    let cellTemplate: any = {};
    setTimeout(() => {
      cellTemplate = {
        idsT: this.idsT,
        imageIcon: this.imageIcon,
        taskThumbnail: this.taskThumbnail,
        info: this.info,
        version: this.version,
        status: this.status,
        receivedDate: this.receivedDate,
        assigned: this.assigned,
      };

      this.dataTableColumns = [];
      console.log(cellTemplate);
      this.dataTableColumns = columns(this.activeTab, cellTemplate);
      //  this.activeTab=='bucket' ? this.dataTableColumns.unshift(this.checkboxColumn) :'';
      console.log(this.dataTableColumns);
      this.currentRole == 'client' ? ( this.dataTableColumns = this.dataTableColumns.filter(item => item.name !== 'Artist') ):'';
    });
  }

  private callbackListener: Function = (payload: any) => {
    this.is_open_detail = false;
    //this.getReviewTaskList(this.tab);
  };

  getStatusList() {
    // return new Promise((resolve, rejected) => {
    //   this.dataService.getOtherData('getStatusList', {})
    //     .subscribe((success) => {
    this.statusArr = [...this.dataService.allStatusList]
    //       resolve(200);

    //     }, (rejected) => {

    //     });
    // });
  }

  getReviewerTasks() {
    //Temporory
    // this.dataService.getListDataPub('getReviewerTasks',{project_id:this.projectId})
    // .subscribe((success) => {
    // });
  }

  ngOnDestroy() {
    if (environment.prodType != 'offline') {
      this.subscribeisUserTasksList.unsubscribe();
      this.isTaskReviewedSubscribed.unsubscribe();
    } else {
      this.timer.unsubscribe();
    }
    this.pusherService.unsubscribeFromChannel(`tasks`);
  }

  // To show default thumbnail icon
  onImageError(event) {
    event.target.src = './assets/images/moviethumnail.png';
  }

  public checkedAction(IS_ALL, row: any, column: any): void {
    if (IS_ALL) {

      let checkboxChecked = $('#checkAll-' + column).is(':checked');
      if (checkboxChecked) {
        this.bucketSelectedTasksId = [];
        $('#checkAll-' + column).prop('checked', false);
        $('.check-sub-' + column).prop('checked', false);

        $('.tasks-ul .active').removeClass('active');

      } else {
        let i;
        this.bucketSelectedTasksId = [];

        for (i = 0; i < this.bucketTaskList.length; i++) {
          let activeArr = {
            'project_id': this.projectId,
            'task_id': this.bucketTaskList[i].task_id,
            'task_assignment_id': this.bucketTaskList[i].id,
            'task_version_id': this.bucketTaskList[i].task_version_id,
            'status': 'start',
            'current_role': this.currentRole,
          };
          $('.task-' + this.bucketTaskList[i].id).addClass('active');
          this.bucketSelectedTasksId.push(activeArr);
        }


        $('#checkAll-' + column).prop('checked', true);
        $('.check-sub-' + column).prop('checked', true);
      }

    } else {
      let checkboxChecked = $('#check-' + row.id).is(':checked');
      if (checkboxChecked) {
        const index: number = this.bucketSelectedTasksId.findIndex(x => {
          return x.task_assignment_id == row.id;
        });
        if (index !== -1) {
          this.bucketSelectedTasksId.splice(index, 1);
        }
        $('#check-' + row.id).prop('checked', false);

        $('.task-' + row.id).removeClass('active');

      } else {
        $('.task-' + row.id).addClass('active');

        let taskAction = 'start';

        if (column == 'wip') {
          taskAction = 'done';
        }

        let activeArr = {
          'project_id': this.projectId,
          'task_id': row.task_id,
          'task_assignment_id': row.id,
          'task_version_id': row.task_version_id,
          'status': taskAction,
          'current_role': this.currentRole,
        };

        this.bucketSelectedTasksId.push(activeArr);

        $('#check-' + row.id).prop('checked', true);
      }
      $('#check-' + row.id).focus();
    }
  }

  openSideWindowMultiAssign(value: any, row: any) {
    this._ng_sidebar_data['taskid'] = row.map((ele) => ele.task_id);
    this._ng_sidebar_data['task'] = true;
    this._ng_sidebar_data['projectId'] = this.projectId;
    this._ng_sidebar_data['department_id'] = 0;
    this._ng_sidebar_data['mode'] = 'edit';
    this._ng_sidebar_data['info'] = '';
    this._ng_sidebar_data['auto_created'] = '';
    this._ng_sidebar_data['undertaking'] = '';
    this._ng_sidebar_data['number'] = '';
    this._ng_sidebar_data['name'] = '';
    this._ng_sidebar_data['department'] = '';
    this._ng_sidebar_data['thumbnail'] = '';
    this._ng_sidebar_data['is_assign'] = true;
    this._ng_sidebar_data['assigned_val'] = 'Assign';
    this._ng_sidebar_data['is_multiple_assign'] = true;
    this._opened = true;

  }

  sendPushNotification(userId, notificationId) {
    this.dataService.getOtherData('getNotificationById', { 'userId': userId, 'notification_id': notificationId })
      .subscribe((success) => {
        if (success.notify.length > 0) {
          let notify = success.notify[0];
          this.notificationsService.sendPushMessage(notify.description, notify.messge, '');
        }
      }, (rejected) => {

      });
  }

  public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {

    this.config.isLoading = this.isLoading;
    this.busy = this.datatableService.onChangeTable(this.config, page)
      .subscribe((success) => {
        this.hideDownloads = false;
        this.readyDownloads = false;
        this.pipelineDownloads = false;
        this.completedDownloads = false;
        if (success.ready.length == 0) {
          this.readyDownloads = true;
        }
        if (success.pipeline.length == 0) {
          this.pipelineDownloads = true;
        }
        if (success.under_review.length == 0) {
          this.hideDownloads = true;
        }
        if (success.completed.length == 0) {
          this.completedDownloads = true;
        }

        console.log(this.activeTab);
        switch (this.activeTab) {
          case 'ready':
            this.datatableRows = success.ready;
            if (success.ready.length > 0) {
              this.datatableRows.forEach((ele) => { ele.showMore = true; });
            }
            break;
          case 'pipeline':
            this.datatableRows = success.pipeline
            if (success.pipeline.length > 0) {
              this.datatableRows.forEach((ele) => { ele.showMore = true; });
            }
            break;
          case 'under_review':
            this.datatableRows = success.under_review;
            break;
          case 'completed':
            this.datatableRows = success.completed
            if (success.completed.length > 0) {
              this.datatableRows.forEach((ele) => { ele.showMore = true; });
            }
            break;
          case 'rejected_inventory':
            this.datatableRows = success.rejectInventory
            if (success.rejectInventory.length > 0) {
              this.datatableRows.forEach((ele) => { ele.showMore = true; });
            }
            break;
          case 'bucket':
            this.datatableRows = success.bucket;
            break;
          default:
            // this.datatableRows = success.ready.length>0 ? success.ready.forEach((ele) => { ele.showMore = true; }) : success.ready;
            this.datatableRows = [];
            break;
        }
        console.log(this.datatableRows);

        this.length = success.total;
        this.totalElements = success.totalElements;
        this.totalItems = success.totalElements;
        this.pageSize = success.totalElements;
        this.noRecordFound = true;
        this.isLoading = false;
      });
  }

  selectTab(tab) {
    this.activeTab = tab;
    this.setDatatableColumns();
    this.isAdvanceSearchEnabled = false;

    if (tab == 'bucket') {
      if (this.currentRole == 'lead' || this.currentRole == 'supervisor') {
        for (let i = 0; i < this.primarySecondaryArr.length; i++) {
          if (this.primarySecondaryArr[i].id === 'All') {
            this.primarySecondaryArr.splice(i, 1);
            break; // Exit the loop after removing the object
          }
        }
      } else if (this.currentRole == 'producer' || this.currentRole == 'production' || this.currentRole == 'production_manager') {
        this.primarySecondaryArr = [
          { "id": 'All', "name": "All", 'checked': true },
          { "id": 'primary', "name": "Primary", 'checked': true },
          { "id": 'secondary', "name": "Secondary", 'checked': true }
        ];
      }
    } else {
      this.primarySecondaryArr = [
        { "id": 'All', "name": "All", 'checked': false },
        { "id": 'primary', "name": "Primary", 'checked': true },
        { "id": 'secondary', "name": "Secondary", 'checked': true }
      ];
    }
    this.length = 0;
    this.datatableService.setParam('getReviewTaskList', 'serverUrl');
    if (tab == 'ready' && !this.isReviewSelection && this.currentRole != 'lead') {
      setTimeout(() => {
        $('#check-user-depts-ready-primary').prop('checked', true);
        $('#check-user-depts-ready-secondary').prop('checked', true);
      }, 200);
    } else if (tab == 'pipeline' && !this.isReviewSelection) {
      setTimeout(() => {
        $('#check-user-depts-pipeline-primary').prop('checked', true);
        $('#check-user-depts-pipeline-secondary').prop('checked', true);
      }, 200);
    } else if (tab == 'under_review' && !this.isReviewSelection && this.currentRole != 'lead') {
      setTimeout(() => {
        $('#check-user-depts-under-review-primary').prop('checked', true);
        $('#check-user-depts-under-review-secondary').prop('checked', true);
      }, 200);
    } else if (tab == 'completed' && !this.isReviewSelection && this.currentRole != 'lead') {
      setTimeout(() => {
        $('#check-user-depts-completed-primary').prop('checked', true);
        $('#check-user-depts-completed-secondary').prop('checked', true);
      }, 200);
    } else if (tab == 'rejected_inventory') {
      this.datatableService.setParam('getUserTaskList', 'serverUrl');
      setTimeout(() => {
        $('#check-user-depts-rejected-primary').prop('checked', true);
        $('#check-user-depts-rejected-secondary').prop('checked', true);
      }, 200);
    }
    else if (tab == 'bucket') {
      this.datatableService.setParam('getUserTaskList', 'serverUrl');
      if (this.currentRole == 'producer' || this.currentRole == 'production' || this.currentRole == 'production_manager') {
        setTimeout(() => {
          $('#check-user-depts-bucket-All').prop('checked', true);
          $('#check-user-depts-bucket-primary').prop('checked', true);
          $('#check-user-depts-bucket-secondary').prop('checked', true);
        }, 200);

      } else {
        setTimeout(() => {
          $('#check-user-depts-bucket-All').prop('checked', false);
          $('#check-user-depts-bucket-primary').prop('checked', true);
          $('#check-user-depts-bucket-secondary').prop('checked', true);
        }, 200);
      }
    }

    this.isFilterApplied = false;

    //saved filters
    this.isLoading = true;

    //this.clearFilter();
    this.tab = tab;
    localStorage.setItem('reviewOpenedTab', tab);
    this.config.where.tab = this.tab;
    // this.config.where.role = ;
    if (tab == 'rejected_inventory' || tab == 'bucket') {
      let temp = JSON.parse(localStorage.getItem('user'));
      this.config.where.userRole = temp.slug;
    }
    this.page = 0;
    this.filterArr = [];
    this.config.where.filterArr = [];
    //this.primaryAndSecondaryArr = [{'entity': 'user-depts','primarySecondaryIds':['primary','secondary']}];

    if ((this.tab == 'bucket' || this.tab == 'rejected_inventory') && (this.currentRole == 'producer' || this.currentRole == 'production' || this.currentRole == 'production_manager')) {
      this.primaryAndSecondaryArr = [{ 'entity': 'user-depts', 'primarySecondaryIds': ['All', 'primary', 'secondary'] }];
    }
    else if (this.isReviewSelection || this.currentRole == 'lead') {
      this.primaryAndSecondaryArr = [{ 'entity': 'user-depts', 'primarySecondaryIds': ['primary', 'secondary'] }];
    }
    else {
      this.primaryAndSecondaryArr = [{ 'entity': 'user-depts', 'primarySecondaryIds': ['primary', 'secondary'] }];
    }

    //this.filterArr = [{'entity': 'user-depts','filterIds':['primary','secondary']}];
    this.config.where.primaryAndSecondaryArr = this.primaryAndSecondaryArr;
    this.taskReview = [];
    this.onChangeTable(this.config, { page: this.page, itemsPerPage: this.itemsPerPage });
    let markAsReadType = [];
    this.displayDetailView(false);
    if (tab == 'ready') {
      if (this.currentRole == 'lead') {
        markAsReadType = ['task_sent_for_review'];
      } else if (this.currentRole == 'client') {
        markAsReadType = ['task_sent_to_client'];
      } else {
        markAsReadType = ['task_sent_for_review', 'task_done'];
      }
    } else if (tab == 'pipeline') {
      markAsReadType = ['task_unapproved', 'task_cbb', 'task_retake'];
    } else if (tab == 'under_review') {
      if (this.currentRole == 'lead') {
        markAsReadType = ['task_done', 'task_sent_to_client'];
      } else if (this.currentRole == 'client') {
        markAsReadType = [];
      } else {
        markAsReadType = ['task_sent_to_client'];
      }
    } else if (tab == 'completed') {
      markAsReadType = ['task_approved'];
    } else if (tab == 'rejected_inventory') {
      markAsReadType = ['rejected_inventory'];
    }

    this.filterArr = [];
    this.filterIds = [];

    let primaryAndSecondaryArr = [];

    if (this.tab == 'bucket' && (this.currentRole == 'producer' || this.currentRole == 'production' || this.currentRole == 'production_manager')) {
      this.primarySecondaryIds = ["All", "primary", "secondary"];
      primaryAndSecondaryArr = [{ 'entity': 'user-depts', 'primarySecondaryIds': ['All', 'primary', 'secondary'] }];
    }
    else if (this.isReviewSelection || this.currentRole == 'lead') {
      this.primarySecondaryIds = [];
      primaryAndSecondaryArr = [{ 'entity': 'user-depts', 'primarySecondaryIds': [] }];
    }
    else {
      //this.primaryAndSecondaryArr = [{'entity': 'user-depts','primarySecondaryIds':['primary','secondary']}];
      this.primarySecondaryIds = ["primary", "secondary"];
      primaryAndSecondaryArr = [{ 'entity': 'user-depts', 'primarySecondaryIds': ['primary', 'secondary'] }];
    }

    this.primaryAndSecondaryArr = primaryAndSecondaryArr;
    $('.check-sub').prop('checked', false);
    if (tab != 'rejected_inventory' && tab != 'bucket') {
      this.getReviewFilters(tab);
    }
    localStorage.setItem('reviewOpenedTab', tab);
    // if (Object.keys(markAsReadType).length > 0) {
    // this.dataService.getOtherData('markAsRead', {'project_id': this.projectId, 'type': markAsReadType})
    //   .subscribe((success) => {
    //   }, (rejected) => {
    //
    //   });
    // }
  }

  getReviewTaskList(tab, calledFrom = '') {
    this.config.isLoading = this.isLoading = false;
    let filterArr = [];
    //this.filterArr = [{'entity': 'user-depts','filterIds':['primary','secondary']}];
    if (this.projectroles) {
      var role = this.projectroles['project_' + this.projectId];
      this.config.where.tab = tab;
      this.config.where.role = role;
      this.config.where.filterArr = this.filterArr;
      this.config.where.primaryAndSecondaryArr = this.primaryAndSecondaryArr;
      this.onChangeTable(this.config, { page: this.page, itemsPerPage: this.itemsPerPage });
    }
  }

  /**Filters**/

  getFilterData(query, column) {
    if (column == 'episodes') {
      this.getEpisodes(query);
    } else if (column == 'sequences') {
      this.getSequences(query);
    } else if (column == 'assets') {
      this.getAssets(query);
    } else if (column == 'shots') {
      this.getShots(query);
    } else if (column == 'departments') {
      this.getDepartments(query);
    } else if (column == 'artists') {
      this.getArtists(query);
    } else if (column == 'status') {
      // this.statusArr = this.statusArr.map(o => {
      //   return o;
      // });

      this.getStatusList();
    }
  }


  getReviewFilters(tab) {
    this.where.tab = tab;
    this.where.projectId = this.projectId;
    this.where.is_external_review = this.is_external_review;
    this.where.currentRole = this.currentRole;
    this.dataService.getListData('getReviewFilters', { 'where': this.where })
      .subscribe((success) => {
        // this.episodes = success.data.episodes;
        // this.sequences = success.data.sequences;
        // this.assets = success.data.assets;
        this.manualTasks = success.data.manualTasks;
        this.isEpisodic = success.data.isEpisodic;
      }, (rejected) => {

      });
  }

  filterAction(IS_ALL, value, row, entity) {
    if (IS_ALL) {
      let checkboxChecked = $('#checkAll').is(':checked');
      let i;
      this.filterIds = [];
      this.primarySecondaryIds = [];

      if (entity == 'user-depts') {
        for (i = 0; i < value.length; i++) {
          this.primarySecondaryIds.push(value[i].id);
        }
        if (checkboxChecked) {
          $('#checkAll').prop('checked', false);
          $('.check-sub').prop('checked', false);
          this.primarySecondaryIds = [];
        } else {
          $('#checkAll').prop('checked', true);
          $('.check-sub').prop('checked', true);
        }
      } else {
        for (i = 0; i < value.length; i++) {
          this.filterIds.push(value[i].id);
        }
        if (checkboxChecked) {
          $('#checkAll').prop('checked', false);
          $('.check-sub').prop('checked', false);
          this.filterIds = [];
        } else {
          $('#checkAll').prop('checked', true);
          $('.check-sub').prop('checked', true);
        }
      }

    } else {
      if (entity == 'user-depts') {
        if (!value) {
          const index: number = this.primarySecondaryIds.indexOf(row);
          if (index !== -1) {
            this.primarySecondaryIds.splice(index, 1);
          }
          $('#check-' + value.id).prop('checked', false);
        } else {
          this.primarySecondaryIds.push(row);
          $('#check-' + value.id).prop('checked', true);
        }
        $('#check-' + value.id).focus();
      } else {
        if (!value) {
          const index: number = this.filterIds.indexOf(row);
          if (index !== -1) {
            this.filterIds.splice(index, 1);
          }
          $('#check-' + value.id).prop('checked', false);
        } else {
          this.filterIds.push(row);
          $('#check-' + value.id).prop('checked', true);
        }
        $('#check-' + value.id).focus();
      }

    }
    if (entity == 'user-depts') {
      this.primaryAndSecondaryArr = [{ 'entity': entity, 'primarySecondaryIds': this.primarySecondaryIds }];
    } else {
      this.filterArr = [{ 'entity': entity, 'filterIds': this.filterIds }];
    }
  }

  applyFilters(entity = '') {
    this.isLoading = true;
    this.isFilterSubmitted = true;
    this.page = 1;
    this.config.isLoading = this.isLoading = false;
    this.tab = localStorage.getItem('reviewOpenedTab');
    this.getReviewTaskList(this.tab, 'applyFilters');
    if (entity != 'user-depts') {
      this.isFilterApplied = true;
    }

    setTimeout(() => {
      this.isFilterSubmitted = false;
    }, 2000);
  }

  clearFilter(entity = '', tab = 'ready') {
    this.isFilterReset = true;
    this.filterArr = [];
    this.filterIds = [];

    if (entity == '' || entity == 'user-depts') {
      let primaryAndSecondaryArr = [];

      if (this.isReviewSelection || this.currentRole == 'lead') {
        primaryAndSecondaryArr = [{ 'entity': 'user-depts', 'primarySecondaryIds': [] }];
        this.primaryAndSecondaryArr = primaryAndSecondaryArr;
        this.config.where.primaryAndSecondaryArr = [{ 'entity': 'user-depts', 'primarySecondaryIds': [] }];
      } else {
        primaryAndSecondaryArr = [{ 'entity': 'user-depts', 'primarySecondaryIds': ['primary', 'secondary'] }];
        this.primaryAndSecondaryArr = primaryAndSecondaryArr;
        this.config.where.primaryAndSecondaryArr = [{ 'entity': 'user-depts', 'primarySecondaryIds': ['primary', 'secondary'] }];
      }

    }

    if (entity != 'user-depts') {
      this.primaryAndSecondaryArr = [];
      this.primarySecondaryIds = [];
    }

    $('.check-sub').prop('checked', false);
    this.onClearFilters(entity);

    this.config.isLoading = false;
    this.config.isClearFilter = true;

    //this.getReviewTaskList(this.tab,'clearFilter');
    this.page = 1;
    this.isFilterApplied = false;
    setTimeout(() => {
      this.isFilterReset = false;
    }, 2000);

  }
  /***Filters End*/

  //Export for Status update
  exportTasksForReview(tab) {
    this.where.tab = tab;
    localStorage.setItem('reviewOpenedTab', tab);
    this.where.isExport = true;
    this.where.projectId = this.projectId;
    this.where.is_external_review = this.is_external_review;
    this.where.currentRole = this.currentRole;

    this.where.role = this.config.where.role;
    this.where.project_id = this.config.where.project_id;
    this.where.is_external_login = this.config.where.is_external_login;
    this.where.filterArr = this.config.where.filterArr;
    this.where.primaryAndSecondaryArr = this.config.where.primaryAndSecondaryArr;
    let reviewTasks = [];

    // if(tab == 'pipeline'){
    //     reviewTasks = this.reviewTaskListPipeline;
    // }else if(tab == 'ready'){
    //     reviewTasks = this.reviewTaskListActive;
    // }else if(tab == 'under_review'){
    //     reviewTasks = this.reviewTaskListUnderReview;
    // }else if(tab == 'completed'){
    //     reviewTasks = this.reviewTaskListCompleted;
    // }
    //let config = this.config;
    this.dataService.getOtherData('exportTasksForReview', { 'where': this.where, 'reviewTasks': reviewTasks, 'config': this.config })
      .subscribe((success) => {
        window.location.href = success.data;
      }, (rejected) => {

      });
    //this.reviewTaskListActive

  }

  /**********    Side Window    **********/

  openCommentSideWindow(data) {
    this._ng_sidebar_comment_data['projectId'] = this.projectId;
    this._ng_sidebar_comment_data['task'] = data;
    this._ng_sidebar_comment_data['allow_status'] = true;
    this._ng_sidebar_comment_data['role'] = this.projectroles['project_' + this.projectId];
    this._ng_sidebar_comment_data['with_comment'] = true;
    this._task_comment_opened = true;
    this.selectedTask['page'] = 'tasks-track';
  }

  onCommentOpenStart() { }

  onCommentOpened() {
    this._loadCommentSideWindow = true;
  }

  onCommentClosed() {
    this._loadCommentSideWindow = false;
  }

  closeSideWindow(data) {

    // console.log('test');
    this.reRender = []; // edited

    this.selectedTask = [];
    this.is_open_detail = false;
    $('.task-' + data.id).removeClass('active');
    // this.tab = localStorage.getItem('reviewOpenedTab');
    // this.getReviewTaskList(this.tab);
  }

  /**********    Checklist Side Window    **********/

  openChecklistSideWindow(data) {
    this._ng_sidebar_checklist_data['projectId'] = this.projectId;
    this._ng_sidebar_checklist_data['task'] = data;
    this._ng_sidebar_checklist_data['allow_status'] = true;
    this._ng_sidebar_checklist_data['is_artist'] = false;
    this._task_checklist_opened = true;
  }

  onChecklistOpenStart() { }

  onChecklistOpened() {
    this._loadChecklistSideWindow = true;
  }

  onChecklistClosed() {
    this._loadChecklistSideWindow = false;
  }

  closeChecklistSideWindow() {
    //this.getReviewTaskList();
  }


  // To open side drawer
  displayDetailView(val, taskDetail: any = [], section = 'ready', selectedTab = 'comment') {

    // console.log('clicked');
    // this.is_open_detail = true;
    this.reRender = [];
    this.reRender[section] = false;
    if (this.selectedTask.id != taskDetail.id) {
      this.is_open_detail = val;
      this.selectedTask = taskDetail;
      this.selectedTask['task'] = taskDetail;
      this.selectedTask['open_from'] = section;
      this.selectedTask['allow_status'] = true;
      this.selectedTask['with_comment'] = true;
      this.selectedTask['is_artist'] = false;
      this.selectedTask['isReview'] = true;
      this.selectedTask['is_external_review'] = this.is_external_review;
      this.selectedTask['screenName'] = 'Review'
      this.selectedTask['selectedTab'] = selectedTab;

      $('.task-li').removeClass('active');
      $('.task-' + taskDetail.id).addClass('active');

      setTimeout(() => {
        this.reRender[section] = true;
      }, 1);
    } else {
      this.selectedTask = [];
      this.is_open_detail = false;
      $('.task-' + taskDetail.id).removeClass('active');
    }
    this.reRender[section] = false;
    if (!val) {
      this.filterArr = [];
      this.filterIds = [];
      //this.getReviewTaskList(this.tab);
    }
  }

  // Import sidewindow opened
  openImportSideWindow(tab) {
    this._import_opened = true;
    this._ng_import_type = 'Tasks Status Update';
  }

  // on side window opened
  onOpened(loadWindow = '') {
    if (loadWindow == 'import') {
      this._loadImportSideWindow = true;
    } else {
      this._loadSideWindow = true;
    }
  }

  // on side window closed
  onClosed(closeWindow = '') {
    this.selectedBucket = [];
    // console.log(se)
    this._loadImportSideWindow = false;
    this._loadSideWindow = false;
  }

  // Import sidewindow closed
  closeImport(event) {
    this._import_opened = false;
    this._loadImportSideWindow = false;
    this.tab = localStorage.getItem('reviewOpenedTab');
    this.getReviewTaskList(this.tab);
  }

  // On advanced filter select
  onFilterSelect(column, $event) {
    if (column == 'episodes') {
      this.filterArr = [{ 'entity': column, 'filterIds': this.taskReview.episodes }];
    } else if (column == 'sequences') {
      this.filterArr = [{ 'entity': column, 'filterIds': this.taskReview.sequences }];
    } else if (column == 'assets') {
      this.filterArr = [{ 'entity': column, 'filterIds': this.taskReview.assets }];
    } else if (column == 'shots') {
      this.filterArr = [{ 'entity': column, 'filterIds': this.taskReview.shots }];
    } else if (column == 'departments') {
      this.filterArr = [{ 'entity': column, 'filterIds': [this.taskReview.departments] }];
    } else if (column == 'artists') {
      this.filterArr = [{ 'entity': column, 'filterIds': this.taskReview.artists }];
    } else if (column == 'status') {
      this.filterArr = [{ 'entity': column, 'filterIds': [this.taskReview.status] }];
    }
    //this.onChangeTable(this.config);
  }

  // On advanced filter unselect
  onFilterUnselect(column, $event) {
    let filtered = this.filterArr[0]['filterIds'].filter(function (value, index, arr) {
      return value.id != $event.id;
    });
    this.filterArr = [{ 'entity': column, 'filterIds': filtered }];
  }

  // Clearing all filters
  onClearFilters(column) {
    if (column == 'episodes') {
      this.taskReview.episodes = [];
      this.filterArr = [];
    } else if (column == 'sequences') {
      this.taskReview.sequences = [];
      this.filterArr = [];
    } else if (column == 'assets') {
      this.taskReview.assets = [];
      this.filterArr = [];
    } else if (column == 'shots') {
      this.taskReview.shots = [];
      this.filterArr = [];
    } else if (column == 'departments') {
      this.taskReview.departments = [];
      this.filterArr = [];
    } else if (column == 'artists') {
      this.taskReview.artists = [];
      this.filterArr = [];
    } else if (column == 'status') {
      this.taskReview.status = [];
      this.filterArr = [];
    } else {
      this.taskReview = [];
      this.filterArr = [];
    }
    this.page = 1;

    this.config.isLoading = this.isLoading = false;
    let filterArr = [];
    //this.filterArr = [{'entity': 'user-depts','filterIds':['primary','secondary']}];
    if (this.projectroles) {
      var role = this.projectroles['project_' + this.projectId];
      this.config.where.role = role;
      this.config.where.filterArr = this.filterArr;
      this.config.where.primaryAndSecondaryArr = this.primaryAndSecondaryArr;
      this.onChangeTable(this.config, { page: this.page, itemsPerPage: this.itemsPerPage });
    }
    //this.onChangeTable(this.config);
  }

  // To get episode list
  getEpisodes(query) {
    this.dataService.getListData('episodesItems', { project_id: this.projectId, 'fromCreateTask': true, 'query': query['query'] })
      .subscribe((success) => {
        this.episodes = success.data;
      }, (rejected) => {

      });
  }

  // To get sequence list
  getSequences(query) {
    this.dataService.getListData('sequencesItems', { project_id: this.projectId, 'fromTaskReview': true, 'query': query['query'] })
      .subscribe((success) => {
        this.sequences = success.data;
      }, (rejected) => {

      });
  }

  // To get asset list
  getAssets(query) {
    this.dataService.getListData('assetsItems', { project_id: this.projectId, 'query': query['query'] })
      .subscribe((success) => {
        this.assets = success.data;
      }, (rejected) => {

      });
  }

  // To get shot list
  getShots(query) {
    this.dataService.getListData('shotsItems', { project_id: this.projectId, 'fromTasks': true, 'query': query['query'] })
      .subscribe((success) => {
        this.shots = success.data;
      }, (rejected) => {

      });
  }

  // To get department list
  getDepartments(query) {
    this.dataService.getListData('getDepartments', { projectId: this.projectId, 'query': query['query'], fromTaskReview: true })
      .subscribe((success) => {
        this.departments = success.data;
      }, (rejected) => {

      });
  }

  // To get artist list
  getArtists(query) {
    this.where.project_id = this.projectId;
    this.where.type = 'emp_name';
    this.dataService.getListData('getEmpDetails', { 'where': this.where, 'query': query['query'] })
      .subscribe((success) => {
        this.artists = success.data;
      }, (rejected) => {

      });
  }



  // trackByreviewTaskListPipeline(index, item) {
  //   return item.id;
  // }

  // trackBybucketTaskList(index, item) {
  //   return item.id;
  // }

  // trackByreviewTaskListActive(index, item) {
  //   return item.id;
  // }

  // trackByreviewTaskListWIP(index, item) {
  //   return item.id;
  // }

  // trackByreviewTaskListUnderReview(index, item) {
  //   return item.id;
  // }

  // trackByreviewTaskListCompleted(index, item) {
  //   return item.id;
  // }

  // trackByreviewTaskListRejectedInv(index, item) {
  //   return item.id;
  // }


  // Assign the task
  openSideWindowAssign(value: any) {
    if ((value.id != undefined && value.id != '')) {
      this._ng_sidebar_data['taskid'] = value.task_id;
    } else if (value.length > 0) {
      this._ng_sidebar_data['taskid'] = value;
    }
    this._ng_sidebar_data['task'] = true;
    this._ng_sidebar_data['projectId'] = this.projectId;
    this._ng_sidebar_data['department_id'] = value.department_id;
    this._ng_sidebar_data['mode'] = 'edit';
    this._ng_sidebar_data['info'] = value.info;
    this._ng_sidebar_data['auto_created'] = value.auto_created;
    this._ng_sidebar_data['undertaking'] = value.undertaking;
    this._ng_sidebar_data['number'] = value.number;
    this._ng_sidebar_data['name'] = value.name;
    this._ng_sidebar_data['department'] = value.department_name;
    this._ng_sidebar_data['thumbnail'] = value.thumbnail;
    this._ng_sidebar_data['is_assign'] = true;
    this._ng_sidebar_data['assigned_val'] = 'Assign';
    this._ng_sidebar_data['is_multiple_assign'] = false;
    this._opened = true;
  }

  // To close create task side drawer
  closeCreateTask($event) {
    this.onChangeTable(this.config, { page: this.page, itemsPerPage: this.itemsPerPage });
    this._opened = false;
  }

  /** Set bg color */
  setBgColor(data: any) {
    if (data != 'dependency' && data) {
      return data;
    } else if (data == 'dependency' && data) {
      return '#ccb9a1'
    }
  }

  // On sorting
  onSort(event) {

  }

  // For user events over datatable cells
  onUserEvent(event) {
    if (event.type == "click") {
      const row = event.row;
      const column = event.column;



    }
  }

  async onPageChange(event) {
    // console.log('page changed');
    this.isLoading = true;
    this.openedPage = event.offset;
    this.pagePayload.page = event.offset * this.pageSize;
    this.config.start = this.pagePayload.page;

    this.page = event.offset + 1;
    // console.log(this.page);
    await this.onChangeTable(this.config, { page: this.page, itemsPerPage: this.itemsPerPage });
    this.isLoading = false;
  }

  // On select event (Only in bucket)
  onSelect({ selected }) {
    this.selectedBucket = selected;
    console.log(selected, this.selectedBucket );
  }

  displayCheck(row) {
    if (row) {
      return true;
    }
  }

  // For row identity by Id
  getId(row) {
    return row.id;
  }


  // For row class by rejected-inv
  getRowClass(row) {

    if(row.is_rej_parent){
      return 'rejected-parent';
    }
  }


  // to Format Received-Date into 'yyyy-mm-dd' format for customDatepipe
  formatReceivedDate(dateString){
    if(dateString){
      const [day, month, year] = dateString.split('-');
      return `${year}-${month}-${day}`;
    }
  }

}

